import styled from '@emotion/styled';

export const Container = styled.div`
  cursor: pointer;
  justify-content: initial !important;
  display: flex;
  align-items: center;
  color: #6fcaff;
  &:hover {
    opacity: 0.8;
  }
  div:not(:last-child) {
    margin-right: 0.5rem;
  }

  svg {
    align-items: center;
    margin-top: 0.7rem;
    width: 1.5rem;
    height: 1.5rem;
    padding-right: 0.2rem;
    background-color: none;
    path {
      fill: #6fcaff;
    }
  }

  div {
    margin: 0px;
    padding: 0px;
    z-index: 1;
    font-weight: bold;
  }
`;
