import { useState } from 'react';
import { Container, InputComponent, Label, Visible } from './styles';
import {
  CLOSE_VISIBLE_EYE_ICON,
  VISIBLE_EYE_ICON,
} from '../../utils/constants/svgs';

interface InputProps {
  passwordVisible?: boolean;
  isGreyScale?: boolean;
  placeholder: string;
  labelText: string;
  required?: boolean;
  name: string;
  type: string;
  value?: string;
  icon?: string;
  change: (
    field: string | number | symbol
  ) => ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({
  passwordVisible,
  isGreyScale,
  placeholder,
  labelText,
  required,
  name,
  type,
  change,
  value,
  icon,
}: InputProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible(!visible);
  };

  return (
    <Container>
      <Label isGreyScale={!!isGreyScale}>{labelText}</Label>
      <div>
        {!!icon && (
          <svg>
            <path d={icon} fill="#6FCAFF" />
          </svg>
        )}
      </div>

      {passwordVisible && (
        <Visible onClick={handleVisible} visible={visible}>
          {visible && <path d={VISIBLE_EYE_ICON} fill="#6FCAFF" />}
          {!visible && <path d={CLOSE_VISIBLE_EYE_ICON} fill="#6FCAFF" />}
        </Visible>
      )}

      <InputComponent
        isGreyScale={!!isGreyScale}
        type={visible ? 'text' : type}
        placeholder={placeholder}
        onChange={change(name)}
        value={value}
        required={required}
        autoComplete="off"
      />
    </Container>
  );
};

export default Input;
