import styled from '@emotion/styled';

export const Main = styled.div`
  width: 100%;
  font-family: Muli;

  h1 {
    font-weight: bold;
  }
  p {
    margin-top: 20px;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 30px;

  tbody {
    a {
      text-decoration: none;
      color: black;
    }
  }
`;

export const Tbody = styled.tbody`
  max-width: 1220px;
`;
