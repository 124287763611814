import React from 'react';
import { Main, Table, Tbody } from './styles';
import TablePaginationHeader from '../../components/TablePaginationHeader/index';
import TableItemTheme from '../../components/TableItemTheme/index';
import api from './../../services/api/index';
import { ThemeProps } from '../../domain/theme';
import { formatDate } from '../../utils/functions/Date';

function ThemeEssayContainer() {
  const PAGINATION_LIMIT = 8;
  const [pages, setPages] = React.useState<number[]>([]);
  const [totalThemes, setTotalThemes] = React.useState(0);
  const [themes, setThemes] = React.useState<ThemeProps[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [startOffset, setStartOffset] = React.useState(1);
  const handleChangeCurrentPage = (current: number): void =>
    setCurrentPage(current);

  React.useEffect(() => {
    setStartOffset((currentPage - 1) * PAGINATION_LIMIT);
  }, [currentPage]);

  React.useEffect(() => {
    api.get('/themes').then(({ data }) => {
      setThemes(data);
      setTotalThemes(data.length);

      const countTotalPagesBasedUserNumberAndLimit = Math.ceil(
        data.length / PAGINATION_LIMIT
      );

      const newPages = Array.from(
        Array(countTotalPagesBasedUserNumberAndLimit)
      ).map((e, i) => i + 1);

      setPages(newPages);
    });
  }, []);
  return (
    <Main>
      <h1>Seja bem-vindo, Administrador</h1>
      <p>Eses são os temas de redação presentes no Aluno Ensina</p>
      <Table>
        <Tbody>
          {themes
            .slice(startOffset, startOffset + PAGINATION_LIMIT)
            .map((theme) => (
              <a href={`essays-theme/${theme.id}`}>
                <TableItemTheme
                  key={theme.name}
                  close_at={formatDate(theme.limit_date)}
                  essayNumber={18}
                  status={
                    new Date(theme.limit_date) > new Date()
                      ? 'Aberto'
                      : 'Fechado'
                  }
                  theme={theme.name}
                  created_at={formatDate(theme.created_at)}
                />
              </a>
            ))}
        </Tbody>
      </Table>
      <TablePaginationHeader
        currentPage={currentPage}
        handleClick={handleChangeCurrentPage}
        pages={pages}
        limit={PAGINATION_LIMIT}
        totalItems={totalThemes}
        label="temas"
      />
    </Main>
  );
}

export default ThemeEssayContainer;
