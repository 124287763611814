export function getBackgroundBasedStatus(status: string): string {
  switch (status) {
    case 'Corrigida':
      return '#CEEFE0';
    case 'A Corrigir':
      return '#FFF7CC';
    default:
      return '#FCEDD4';
  }
}

export function getColorBasedStatus(status: string): string {
  switch (status) {
    case 'Corrigida':
      return '#0AAE65';
    case 'A Corrigir':
      return '#FFD800';
    default:
      return '#F1A728';
  }
}
