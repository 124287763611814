export function isValidDate(str: string) {
  var date_regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
  if (!date_regex.test(str)) {
    return false;
  }
  return true;
}

export function formatExpirationDate(date: string): Date {
  const newDate = date.split('/');
  const year = parseInt(newDate[2]);
  const month = parseInt(newDate[1]) - 1;
  const day = parseInt(newDate[0]) + 1;
  return new Date(Date.UTC(year, month, day, 2, 59, 0));
}

export function formatDate(date: string) {
  const data = new Date(date),
    day = data.getDate().toString(),
    dayF = day.length === 1 ? '0' + day : day,
    month = (data.getMonth() + 1).toString(),
    monthF = month.length === 1 ? '0' + month : month,
    yearF = data.getFullYear();
  return dayF + '/' + monthF + '/' + yearF;
}

export function handleDateCommentLecture(date: string) {
  const commentDate = new Date(date).toLocaleDateString().split('/');
  const atualDate = new Date().toLocaleDateString().split('/');

  if (parseInt(atualDate[2]) === parseInt(commentDate[2])) {
    if (parseInt(atualDate[1]) === parseInt(commentDate[1])) {
      if (parseInt(atualDate[0]) >= parseInt(commentDate[0])) {
        return parseInt(atualDate[0]) - parseInt(commentDate[0]) === 0
          ? 'Hoje'
          : parseInt(atualDate[0]) - parseInt(commentDate[0]) + ' dias atrás';
      }
    } else {
      return parseInt(atualDate[1]) - parseInt(commentDate[1]) === 1
        ? parseInt(atualDate[1]) - parseInt(commentDate[1]) + ' mês'
        : parseInt(atualDate[1]) - parseInt(commentDate[1]) + ' meses';
    }
  } else {
    return parseInt(atualDate[2]) - parseInt(commentDate[2]) + ' anos atrás';
  }
  return;
}
