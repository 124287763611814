import { Container, InputComponent, Label } from './styles';

interface SearchProps {
  placeholder: string;
  labelText: string;
  name: string;
  type: string;
  value?: string;
  change: (search: string) => void;
}

const Search = ({
  placeholder,
  labelText,
  name,
  change,
  value,
}: SearchProps) => {
  return (
    <Container>
      <Label>{labelText || ''}</Label>
      <svg>
        <path
          d="M20.71 19.2899L17 15.6099C18.4401 13.8143 19.1375 11.5352 18.9488 9.2412C18.7601 6.94721 17.6997 4.81269 15.9855 3.27655C14.2714 1.74041 12.0338 0.919414 9.73295 0.982375C7.43207 1.04534 5.24275 1.98747 3.61517 3.61505C1.98759 5.24263 1.04546 7.43194 0.982497 9.73283C0.919537 12.0337 1.74053 14.2713 3.27667 15.9854C4.81281 17.6996 6.94733 18.76 9.24133 18.9487C11.5353 19.1374 13.8144 18.44 15.61 16.9999L19.29 20.6799C19.383 20.7736 19.4936 20.848 19.6154 20.8988C19.7373 20.9496 19.868 20.9757 20 20.9757C20.132 20.9757 20.2627 20.9496 20.3846 20.8988C20.5065 20.848 20.6171 20.7736 20.71 20.6799C20.8903 20.4934 20.991 20.2442 20.991 19.9849C20.991 19.7256 20.8903 19.4764 20.71 19.2899ZM10 16.9999C8.61556 16.9999 7.26218 16.5894 6.11103 15.8202C4.95989 15.051 4.06268 13.9578 3.53287 12.6787C3.00306 11.3996 2.86443 9.99214 3.13453 8.63427C3.40463 7.27641 4.07131 6.02912 5.05028 5.05016C6.02925 4.07119 7.27653 3.4045 8.63439 3.13441C9.99226 2.86431 11.3997 3.00293 12.6788 3.53275C13.9579 4.06256 15.0511 4.95977 15.8203 6.11091C16.5895 7.26206 17 8.61544 17 9.9999C17 11.8564 16.2625 13.6369 14.9498 14.9497C13.637 16.2624 11.8565 16.9999 10 16.9999Z"
          fill="#9AA0BA"
        />
      </svg>

      <InputComponent
        name={name}
        type={'text'}
        placeholder={placeholder || ''}
        onChange={(e) => change(e.target.value)}
        value={value}
        required
      />
    </Container>
  );
};

export default Search;
