import Layout from '../../containers/Layout';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { PAGINATION_LECTURES_LIMIT } from '../../config/lecture/table';
import { Dashboard, CardsProps } from '../../components/Dashboard';
import { showMessage } from '../../hooks/messages';
import TablePaginationHeader from '../../components/TablePaginationHeader';
import FilterComponent from '../../components/FilterComponent';
import { isAuth } from '../../hooks/auth';
import { UserProps } from '../../domain/user';
import { EssayGroupProps } from '../../domain/essayGroups';
import { sortTable } from '../../utils/functions/Table/table-functions';
import { formatDate } from '../../utils/functions/Date';
import { onlyNumbers } from '../../utils/functions/Dashboard';
import * as Svg from '../../utils/constants/svgs';
import * as DB from './styles';
import * as Input from '../../components/SelectDashboard/styles';

type Props = 'name' | 'students' | 'pending' | 'created_at';

type TeacherToShowProps = {
  id: number;
  name: string;
  students: number;
  pending: number;
  created_at: string;
};

type FilterProps = {
  prop: Props;
  isAscending: boolean;
};

type TableHeaderObj = {
  name: string;
  sort: string;
};

type TableHeadProps = {
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
  thead: TableHeaderObj[];
  getIsAscending: (prop: string) => boolean;
};

const Teachers = () => {
  const history = useHistory();
  const thead: TableHeaderObj[] = [
    { name: 'Nome do corretor', sort: 'name' },
    { name: 'Alunos', sort: 'students' },
    { name: 'Correções pendentes', sort: 'pending' },
    { name: 'Data de cadastro', sort: 'created_at' },
  ];
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [teachers, setTeachers] = useState<UserProps[]>([]);
  const [groups, setGroups] = useState<EssayGroupProps[]>([]);
  const [totalPage, setTotalPage] = useState<number[]>([]);
  const [startOffset, setStartOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filterStudent, setFilterStudent] = useState<number | null>(null);
  const [filterPending, setFilterPending] = useState<number | null>(null);
  const [essaysSent, setEssaysSent] = useState(0);
  const [essaysPending, setEssaysPending] = useState(0);
  const [essaysCorrected, setEssaysCorrected] = useState(0);
  const [filter, setFilter] = useState<FilterProps>({
    prop: 'name',
    isAscending: false,
  });
  const cards: CardsProps[] = [
    {
      label: 'Redações enviadas',
      text: essaysSent.toString(),
    },
    {
      label: 'Correções pendentes',
      text: essaysPending.toString(),
    },
    {
      label: 'Correções realizadas',
      text: essaysCorrected.toString(),
    },
  ];

  useEffect(() => {
    if (!isAuth()) {
      history.push('/');
    }

    Promise.all([
      api.get(`/users?role=4`).then(({ data }) => {
        setTeachers(data);
        const totalPages = Math.round(data.length / PAGINATION_LECTURES_LIMIT);
        const arrayPages = Array.from({ length: totalPages }, (_, i) => i + 1);
        setTotalPage(arrayPages);
        setTotal(data.length);
        setIsTableLoading(false);
      }),
      api.get('/essay-groups').then(({ data }) => {
        setGroups(data);
      }),
      api
        .get('/essays/count?essay_status=1')
        .then(({ data }) => setEssaysSent(data)),
      api
        .get('/essays/count?essay_status=2')
        .then(({ data }) => setEssaysPending(data)),
      api
        .get('/essays/count?essay_status=3')
        .then(({ data }) => setEssaysCorrected(data)),
    ]).catch(() => {
      showMessage({
        intent: 'error',
        message: 'Erro ao buscar dados',
      });
    });
  }, [history]);

  const handleChangePage = (page: number) => {
    setPage(page);
    setStartOffset((page - 1) * PAGINATION_LECTURES_LIMIT);
  };

  const getIsAscending = (value: string): boolean => {
    return filter.prop === value && filter.isAscending === true;
  };

  const formatLecture = () => {
    const teachersFormated = teachers.map(({ id, name, created_at }) => {
      const teacherGroup = groups.find((g) => g.id_teacher.id === id);
      const students = teacherGroup?.users.length || 0;
      const pending =
        teacherGroup?.essays.filter((e) => +e.essay_status === 1).length || 0;

      return { id, name, created_at, students, pending };
    });

    return teachersFormated.filter((t) => {
      if (filterStudent || filterPending) {
        if (filterStudent && filterPending) {
          return t.students === filterStudent && t.pending === filterPending;
        }
        return t.students === filterStudent || t.pending === filterPending;
      }
      return true;
    });
  };

  const arrayFiltered = formatLecture().filter((elem) =>
    elem.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Layout>
      <DB.Container>
        <Dashboard
          title="Corretores"
          subtitle="Dashboard de corretores"
          cards={cards}
        />
        {isTableLoading ? (
          <DB.Loading />
        ) : (
          <>
            <FilterComponent
              menu="teachers"
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              filters={[filterStudent, filterPending]}
            >
              <DB.Filter>
                <Input.Container>
                  <h2>Alunos</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Número de alunos"
                      value={filterStudent?.toString() ?? ''}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterStudent(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
                <Input.Container>
                  <h2>Correções pendentes</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Número de pendencias"
                      value={filterPending?.toString()}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterPending(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
              </DB.Filter>
            </FilterComponent>
            <DB.Table>
              <TableHeader
                thead={thead}
                filter={filter}
                setFilter={setFilter}
                getIsAscending={getIsAscending}
              />
              <DB.TableBody>
                {sortTable(arrayFiltered, filter)
                  .slice(startOffset, startOffset + PAGINATION_LECTURES_LIMIT)
                  .map((teacher: TeacherToShowProps) => (
                    <TableItem
                      key={teacher.name + teacher.id}
                      teacher={teacher}
                    />
                  ))}
              </DB.TableBody>
            </DB.Table>
            <TablePaginationHeader
              pages={totalPage}
              limit={PAGINATION_LECTURES_LIMIT}
              totalItems={total}
              currentPage={page}
              handleClick={handleChangePage}
              label="aulas"
            />
          </>
        )}
      </DB.Container>
    </Layout>
  );
};

const TableHeader = ({
  filter,
  setFilter,
  thead,
  getIsAscending,
}: TableHeadProps) => {
  return (
    <DB.TableHead>
      <DB.TR>
        {thead.map(({ name, sort }, index) => {
          const { prop, isAscending } = filter;
          return (
            <DB.TH key={index}>
              <div
                onClick={() => {
                  if (sort === prop) {
                    return setFilter({ prop, isAscending: !isAscending });
                  }
                  setFilter({ prop: sort as Props, isAscending: false });
                }}
              >
                {name}
                <DB.SvgArrow
                  path={Svg.ARROW_DOWN}
                  prop={getIsAscending(sort)}
                />
              </div>
            </DB.TH>
          );
        })}
      </DB.TR>
    </DB.TableHead>
  );
};

const TableItem = ({ teacher }: any) => {
  const history = useHistory();
  return (
    <DB.TR onClick={() => history.push(`/corrector/${teacher.id}`)}>
      <DB.TD>{teacher.name}</DB.TD>
      <DB.TD>{teacher.students}</DB.TD>
      <DB.TD>{teacher.pending}</DB.TD>
      <DB.TD>{formatDate(teacher.created_at)}</DB.TD>
    </DB.TR>
  );
};

export default Teachers;
