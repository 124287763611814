import { BreadCrumbs, BreadCrumb } from './styles';

type BreadCrumbProps = {
  name: string;
  active?: boolean;
  redirect?: string;
};

type BreadCrumbsProps = {
  crumbs: BreadCrumbProps[];
};

const BreadCrumbsComponent = ({ crumbs }: BreadCrumbsProps) => {
  return (
    <BreadCrumbs>
      {crumbs.map(({ name, active, redirect }, index) => {
        return (
          <BreadCrumb
            key={name + index}
            active={active}
            redirect={!!redirect}
            to={redirect ?? '#'}
          >
            {name}
          </BreadCrumb>
        );
      })}
    </BreadCrumbs>
  );
};

export default BreadCrumbsComponent;
