import styled from '@emotion/styled';

export const Title = styled.h1`
  font-family: inherit;
  font-weight: bold;
  height: 38px;
  font-size: 2rem;
  margin-top: 20px;
  color: #050a24;
`;

export const Subtitle = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Cards = styled.div`
  display: flex;
  > div {
    margin-right: 1.5rem;
  }
`;
