import React from 'react';
import { Container } from './styles';

interface ButtonProps {
  type: 'button' | 'submit' | 'reset';
  text?: string;
  disabled?: boolean;
}

const Button = ({ type, text, disabled }: ButtonProps) => {
  return (
    <Container type={type} disabled={disabled}>
      {text}
    </Container>
  );
};

export default Button;
