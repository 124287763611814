import { Container } from './styles';
import { CHECKBOX } from './../../utils/constants/svgs';

type CheckBoxItemTableProps = {
  isSelected: boolean;
  colorSvg?: string;
  borderColor?: string;
  backgroundColor?: string;
};

function CheckBoxItemTable({ isSelected }: CheckBoxItemTableProps) {
  return (
    <Container isSelected={isSelected}>
      {isSelected && (
        <svg>
          <path d={CHECKBOX} />
        </svg>
      )}
    </Container>
  );
}

export default CheckBoxItemTable;
