import styled from '@emotion/styled';

interface VisibleProps {
  visible: boolean;
}

interface inputProps {
  isGreyScale: boolean;
  placeholder: string;
}

interface labelProps {
  isGreyScale: boolean;
  children: string;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 0px;
  padding: 0px;
  position: relative;
  div {
    svg {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 10px;
      top: 18px;
    }
  }
`;

export const Label = styled.label<labelProps>`
  position: absolute;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  text-align: left;
  font-family: 'Mulish', sans-serif;
  font-size: ${({ isGreyScale }) => (isGreyScale ? '1rem' : '1rem')};
  letter-spacing: 0.8px;
  font-weight: bold;
  color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#6FCAFF;')};
  text-transform: uppercase;
  opacity: 1;
  z-index: 2;
`;

export const InputComponent = styled.input<inputProps>`
  width: 100%;
  height: 70px;

  text-align: bottom;
  font-family: 'Mulish', sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.8px;
  color: #050a24;

  padding-top: 1.8rem;
  padding-left: 0.8rem;
  margin-bottom: 1.5rem;
  background-color: inherit;

  border: 0.3px solid;
  border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#6FCAFF;')};
  border-radius: 8px;

  z-index: 1;

  &::focus-visible {
    border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#0762d9')};
  }
  ::placeholder {
    color: #9aa0ba;
  }
`;

export const Visible = styled.svg<VisibleProps>`
  fill: #6fcaff;
  position: absolute;
  left: 88%;
  margin: 20px 0;
  width: 30px;
  height: 25px;

  top: ${({ visible }) => (visible ? '3px' : '0px')};

  &:hover {
    cursor: pointer;
  }
`;
