import styled from '@emotion/styled';

interface ButtonProps {
  background: string;
  color: string;
}

export const TR = styled.tr`
  width: 100%;
  height: 70px;
  max-width: 1200px;
  margin-top: 10px;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 2fr 1.5fr 0.5fr;
  justify-content: space-between;

  text-transform: uppercase;
  background-color: white;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export const TD = styled.td`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow-x: hidden;
  padding: 5px;
`;

export const Title = styled.div`
  color: #6fcaff;
  text-align: left;
`;

export const Text = styled.div`
  display: flex;
  height: 100px;
  align-items: center;
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
`;

export const Status = styled.div<ButtonProps>`
  width: 130px;
  height: 40px;

  display: flex;
  align-items: center;

  padding-left: 7px;
  border-radius: 5px;
  color: ${({ color }) => (color ? color : '')};
  background-color: ${({ background }) => (background ? background : '')};

  div:first-of-type {
    background: ${({ color }) => (color ? color : 'white')};
    border-radius: 2px;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Button = styled.div`
  width: 30px;
  background: #ebecf1;
  border-radius: 5px;

  svg {
    fill: #9aa0ba;
    height: 25px;
    width: 30px;
    margin-top: 6px;
    margin-left: 6px;
  }

  &:hover {
    cursor: pointer;
  }
`;
