import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

interface ItemProps {
  selected?: boolean;
}

interface ItemsProps {
  show?: boolean;
}

export const Menu = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
`;

export const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: #fefeff;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  label {
    color: #9aa0ba;
  }
  img {
    height: unset;
    width: 40px;
    margin-right: 0.5rem;
  }
  :hover {
    cursor: pointer;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  > div:not(:first-of-type) {
    margin-left: 2rem;
  }
`;

export const Select = styled.div<ItemProps>`
  display: flex;
  align-items: center;
`;

const GrowthAnimation = keyframes`
from {
  max-height: 0px;
  padding: 0px 1rem;
  overflow: hidden;
}

to {
  max-height: 300px;
}
`;

export const Items = styled.div<ItemsProps>`
  margin: 0px;
  margin-left: 0px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  min-width: 250px;
  margin-top: 1rem;
  background-color: #fefeff;
  border-radius: 16px;
  padding: 1rem;
  position: absolute;
  top: 44px;
  animation: ${GrowthAnimation} 400ms ease-in-out;
  z-index: 11;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);

  button {
    color: #050a24;
    text-transform: uppercase;
    font-size: 1rem;
    border: none;
    background: none;
    text-align: left;
    margin: 0px;
    padding: 1rem;
    border-radius: 8px;
  }

  button:not(:first-of-type) {
    margin-top: 0.5rem;
  }

  button:hover {
    background-color: #3e79c4;
    color: #fff;
  }
`;

export const Svg = styled.svg<ItemsProps>`
  cursor: pointer;
  height: 10px;
  width: 12px;
  fill: #9aa0ba;
  margin-left: 0.5rem;
  transform: ${({ show }) => (show ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
`;

export const Item = styled.div<ItemProps>`
  text-transform: uppercase;
  font-size: 1rem;
  color: ${({ selected }) => (selected ? '#050a24' : '#9aa0ba')};
  border-bottom: ${({ selected }) => (selected ? '2px solid #0e57b5' : 'none')};
  padding-bottom: ${({ selected }) => (selected ? '5px' : '7px')};
  label {
    cursor: pointer;
    letter-spacing: 0px;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  label {
    cursor: pointer;
    color: #a8aabc;
  }
  svg {
    width: 20px;
    height: 18px;
    fill: #a8aabc;
    margin-left: 0.5rem;
  }
  :hover {
    label {
      color: #db3535;
    }
    svg {
      fill: #db3535;
    }
  }
`;
