import CheckBoxItemTable from '../CheckBoxItemTable/index';
import { TR, TD, Title, Text, Status } from './styles';
import {
  getBackgroundBasedStatus,
  getColorBasedStatus,
} from '../../utils/functions/Essay/essay-status';

type ItemTableEssayThemeProps = {
  id: number;
  status: string;
  title: string;
  isSelected: boolean;
  created_by: string;
  created_at: string;
  click: (id: number) => void;
};

const ItemTableEssayTheme = ({
  id,
  status,
  title,
  isSelected,
  created_by,
  created_at,
  click,
}: ItemTableEssayThemeProps) => {
  return (
    <TR>
      <TD width={30} onClick={() => click(id)}>
        <CheckBoxItemTable isSelected={isSelected} />
      </TD>
      <TD width={600}>
        <Title>Título</Title> <Text>{title}</Text>
      </TD>
      <TD width={150}>
        <Title>Escrita Por</Title>
        <Text>{created_by}</Text>
      </TD>
      <TD width={150}>
        <Title>Enviada em</Title>
        <Text>{created_at}</Text>
      </TD>

      <TD width={150}>
        <Text>
          <Status
            background={getBackgroundBasedStatus(status)}
            color={getColorBasedStatus(status)}
          >
            <div></div>
            <div>{status}</div>
          </Status>
        </Text>
      </TD>
    </TR>
  );
};

export default ItemTableEssayTheme;
