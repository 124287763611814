import { Container } from './styles';
import { useHistory } from 'react-router-dom';
const Back = () => {
  let history = useHistory();
  return (
    <Container onClick={() => history.goBack()}>
      <div>
        <svg>
          <path d="M14.25 6.75H4.76251L8.88751 2.6375C9.12289 2.40212 9.25512 2.08288 9.25512 1.75C9.25512 1.41713 9.12289 1.09788 8.88751 0.862503C8.65213 0.627123 8.33289 0.494888 8.00001 0.494888C7.66713 0.494888 7.34789 0.627123 7.11251 0.862503L0.862508 7.1125C0.748707 7.23138 0.659501 7.37156 0.600008 7.525C0.474985 7.82933 0.474985 8.17068 0.600008 8.475C0.659501 8.62844 0.748707 8.76862 0.862508 8.8875L7.11251 15.1375C7.22871 15.2547 7.36696 15.3477 7.51929 15.4111C7.67161 15.4746 7.83499 15.5073 8.00001 15.5073C8.16502 15.5073 8.3284 15.4746 8.48073 15.4111C8.63305 15.3477 8.7713 15.2547 8.88751 15.1375C9.00467 15.0213 9.09766 14.883 9.16112 14.7307C9.22458 14.5784 9.25726 14.415 9.25726 14.25C9.25726 14.085 9.22458 13.9216 9.16112 13.7693C9.09766 13.617 9.00467 13.4787 8.88751 13.3625L4.76251 9.25H14.25C14.5815 9.25 14.8995 9.11831 15.1339 8.88389C15.3683 8.64947 15.5 8.33152 15.5 8C15.5 7.66848 15.3683 7.35054 15.1339 7.11612C14.8995 6.8817 14.5815 6.75 14.25 6.75Z"></path>
          <path d="M14.25 6.75H4.76251L8.88751 2.6375C9.12289 2.40212 9.25512 2.08288 9.25512 1.75C9.25512 1.41713 9.12289 1.09788 8.88751 0.862503C8.65213 0.627123 8.33289 0.494888 8.00001 0.494888C7.66713 0.494888 7.34789 0.627123 7.11251 0.862503L0.862508 7.1125C0.748707 7.23138 0.659501 7.37156 0.600008 7.525C0.474985 7.82933 0.474985 8.17068 0.600008 8.475C0.659501 8.62844 0.748707 8.76862 0.862508 8.8875L7.11251 15.1375C7.22871 15.2547 7.36696 15.3477 7.51929 15.4111C7.67161 15.4746 7.83499 15.5073 8.00001 15.5073C8.16502 15.5073 8.3284 15.4746 8.48073 15.4111C8.63305 15.3477 8.7713 15.2547 8.88751 15.1375C9.00467 15.0213 9.09766 14.883 9.16112 14.7307C9.22458 14.5784 9.25726 14.415 9.25726 14.25C9.25726 14.085 9.22458 13.9216 9.16112 13.7693C9.09766 13.617 9.00467 13.4787 8.88751 13.3625L4.76251 9.25H14.25C14.5815 9.25 14.8995 9.11831 15.1339 8.88389C15.3683 8.64947 15.5 8.33152 15.5 8C15.5 7.66848 15.3683 7.35054 15.1339 7.11612C14.8995 6.8817 14.5815 6.75 14.25 6.75Z"></path>
        </svg>
      </div>
      <div>Voltar</div>
    </Container>
  );
};

export default Back;
