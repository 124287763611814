import styled from '@emotion/styled';

interface CheckboxProps {
  isChecked: boolean;
}
export const Container = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 10px 0px;
  width: 100%;
`;

export const Check = styled.div<CheckboxProps>`
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: ${({ isChecked }) => (isChecked ? '#0e57b5' : '#fefeff')};
  border: ${({ isChecked }) =>
    isChecked ? '2px solid #0e57b5 ' : '2px solid #a8aabc'};
  svg {
    width: 20px;
    height: 20px;
    transform: translateX(-4px) translateY(-4px) scale(0.9);
    fill: #fff;
  }
`;

export const HiddenCheck = styled.input`
  position: absolute;
  z-index: 11;
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  visibility: hidden;
`;
