import styled from '@emotion/styled';

type ContainerProps = {
  isSelected: boolean;
};
export const Container = styled.div<ContainerProps>`
  height: 22px;
  width: 22px;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#6FCAFF' : '#CDCED3')};
  border-radius: 3px;
  svg {
    fill: ${({ isSelected }) => (isSelected ? '#6FCAFF' : 'white')};
    height: 20px;
    width: 20px;
    background-color: white;
  }
`;
