import { Container, PaginationItem, Point } from './styles';

type TablePaginationHeaderProps = {
  limit: number;
  totalItems: number;
  pages: number[];
  currentPage: number;
  label: string;
  handleClick: (current: number) => void;
};

function prevCurrentPage(
  currentPage: number,
  handleClick: (current: number) => void
) {
  if (currentPage > 1) {
    return (
      <PaginationItem onClick={() => handleClick(currentPage - 1)}>
        {'<'}
      </PaginationItem>
    );
  }
  return null;
}
function nextCurrentPage(
  currentPage: number,
  pagesLenght: number,
  handleClick: (current: number) => void
) {
  if (pagesLenght > currentPage) {
    return (
      <PaginationItem onClick={() => handleClick(currentPage + 1)}>
        {'>'}
      </PaginationItem>
    );
  }
  return null;
}

function pageAfterPoints(
  currentPage: number,
  numberArrayPage: number,
  totalPages: number
) {
  return (
    numberArrayPage < 4 ||
    numberArrayPage === currentPage ||
    numberArrayPage === totalPages
  );
}

function pageBeforePoints(
  currentPage: number,
  numberArrayPage: number,
  totalPages: number
) {
  return (
    currentPage > 3 &&
    numberArrayPage !== totalPages &&
    (numberArrayPage - 1 === currentPage || numberArrayPage + 1 === currentPage)
  );
}

function TablePaginationHeader({
  limit,
  totalItems,
  pages,
  currentPage,
  handleClick,
  label,
}: TablePaginationHeaderProps) {
  return (
    <Container>
      <p>
        Exibindo {limit > totalItems ? totalItems : limit} de
        {` ${totalItems} ${label}`}
      </p>
      <div>
        {prevCurrentPage(currentPage, handleClick)}

        {pages.map((page) => {
          return pageBeforePoints(currentPage, page, pages.length) ? (
            <Point key={page}>...</Point>
          ) : (
            pageAfterPoints(currentPage, page, pages.length) && (
              <PaginationItem
                onClick={() => handleClick(page)}
                isSelect={page === currentPage}
                key={page}
              >
                {page}
              </PaginationItem>
            )
          );
        })}

        {nextCurrentPage(currentPage, pages.length, handleClick)}
      </div>
    </Container>
  );
}

export default TablePaginationHeader;
