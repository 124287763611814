import { useState, useEffect } from 'react';
import Layout from '../../containers/Layout';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api/index';
import { UserProps } from '../../domain/user';
import { sortTable } from '../../utils/functions/Table/table-functions';
import { showMessage } from '../../hooks/messages';
import { isAuth } from '../../hooks/auth';
import BreadCrumb from '../../components/BreadCrumbs';
import Modal from '../../components/Modal';
import { EssayGroupProps } from '../../domain/essayGroups';
import { formatDate } from '../../utils/functions/Date';
import { ThemeProps } from '../../domain/theme';
import { getColorBasedStatus } from '../../utils/functions/Essay/essay-status';
import FilterComponent from '../../components/FilterComponent';
import { onlyNumbers } from '../../utils/functions/Dashboard';
import * as Svg from '../../utils/constants/svgs';
import * as Corrector from './styles';
import * as Input from '../../components/SelectDashboard/styles';

interface ParamTypes {
  id: string;
}

type InfoProps = { label: string; text: string };

type Props =
  | 'name'
  | 'essays'
  | 'pending'
  | 'created_at'
  | 'status'
  | 'title'
  | 'theme';

type FilterProps = {
  prop: Props;
  isAscending: boolean;
};

type TableHeaderObj = {
  name: string;
  sort: string;
  center?: boolean;
};

type TableHeadProps = {
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
  isStudentTable: boolean;
  getIsAscending: (prop: string) => boolean;
};

const Teacher = () => {
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const [group, setGroup] = useState<EssayGroupProps>();
  const [teacher, setTeacher] = useState<UserProps>({
    name: '',
    email: '',
  } as UserProps);
  const [themes, setThemes] = useState<ThemeProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [isStudentTable, setIsStudentTable] = useState(true);
  const [filterEssaySent, setFilterEssaySent] = useState<number | null>(null);
  const [filterPending, setFilterPending] = useState<number | null>(null);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<FilterProps>({
    prop: 'name',
    isAscending: false,
  });

  useEffect(() => {
    if (!isAuth()) {
      history.push('/');
    }

    Promise.all([
      api.get(`/users/${id}`).then(({ data }) => {
        setTeacher(data);
        setIsLoading(false);
      }),
      api.get(`/essay-groups?id_teacher=${id}`).then(({ data }) => {
        setGroup(data[0]);
      }),
      api.get('/themes').then(({ data }) => setThemes(data)),
    ]).catch(() =>
      showMessage({
        intent: 'error',
        message: 'Erro ao buscar dados',
      })
    );
  }, [id, history]);

  const closeDeleteModal = () => setShowDeleteModal(false);

  const closePasswordModal = () => setShowPasswordModal(false);

  const getIsAscending = (value: string): boolean => {
    return filter.prop === value && filter.isAscending === true;
  };

  const users = group?.users || [];
  const allEssays = group?.essays || [];

  const formatUser = () => {
    const usersToShow = users.map((u) => {
      const userEssays = allEssays.filter((e) => e.idUser === u.id);
      const pending = userEssays.filter((e) => e.essay_status === 1).length;
      return {
        name: u.name,
        essays: userEssays.length,
        pending,
        created_at: u.created_at,
        status: u.essay_authorized,
      };
    });
    return usersToShow.filter((t) => {
      if (filterEssaySent || filterPending) {
        return t.essays === filterEssaySent || t.pending === filterPending;
      }
      return true;
    });
  };

  const formatEssay = () => {
    const essaysToShow = allEssays.map((e) => {
      const { title, created_at, essay_status } = e;
      const name = users.find((u) => u.id === e.idUser)?.name;
      const theme = themes.find((t) => e.theme === t.id);
      return {
        id: theme?.id ?? 0,
        title: title ?? '',
        name: name ?? '',
        theme: theme?.name ?? '',
        created_at,
        status: essay_status ?? 0,
      };
    });
    return essaysToShow;
  };

  const arrayFiltered = isStudentTable
    ? formatUser().filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      )
    : formatEssay().filter(
        ({ title, name, theme }) =>
          title.toLowerCase().includes(search.toLowerCase()) ||
          name?.toLowerCase().includes(search.toLowerCase()) ||
          theme?.toLowerCase().includes(search.toLowerCase())
      );

  const { name, email } = teacher;

  return (
    <Layout>
      {isLoading ? (
        <Corrector.Loading />
      ) : (
        <Corrector.Background>
          <Modal show={showPasswordModal}>
            <Modal.Header close={closePasswordModal}>
              Alterar senha
            </Modal.Header>
            <Modal.Password id={teacher.id} closeModal={closePasswordModal} />
          </Modal>
          <Modal show={showDeleteModal}>
            <Modal.Header close={closeDeleteModal}>
              Excluir corretor
            </Modal.Header>
            <Modal.Delete
              id={teacher.id}
              groupId={group?.id}
              users={!!formatUser().length}
              closeModal={closeDeleteModal}
            />
          </Modal>
          <BreadCrumb
            crumbs={[
              {
                name: 'Corretores',
                active: true,
                redirect: '/correctors',
              },
              { name },
            ]}
          />
          <Corrector.Title>{name}</Corrector.Title>
          <Corrector.Content>
            <Corrector.Subtitle>
              <Corrector.Text>Dados do corretor</Corrector.Text>
              <Corrector.Delete onClick={() => setShowDeleteModal(true)}>
                Excluir corretor
              </Corrector.Delete>
            </Corrector.Subtitle>
            <Corrector.Card>
              <Info label="Nome" text={name} />
              <Corrector.DoubleInfo>
                <Info label="Email" text={email} />
                <Info label="Senha" text={'*******'} />
              </Corrector.DoubleInfo>
              <Corrector.Button onClick={() => setShowPasswordModal(true)}>
                Alterar senha
              </Corrector.Button>
            </Corrector.Card>
          </Corrector.Content>
          <div>
            <Corrector.Toggle>
              <Corrector.ToggleButton
                selected={isStudentTable}
                onClick={() => {
                  setIsStudentTable(true);
                }}
              >
                <Corrector.ToggleLabel>Alunos</Corrector.ToggleLabel>
              </Corrector.ToggleButton>
              <Corrector.ToggleButton
                selected={!isStudentTable}
                onClick={() => {
                  setIsStudentTable(false);
                }}
              >
                <Corrector.ToggleLabel>Correções</Corrector.ToggleLabel>
              </Corrector.ToggleButton>
            </Corrector.Toggle>
            <FilterComponent
              menu={isStudentTable ? 'students' : 'correction'}
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              filters={
                isStudentTable ? [filterEssaySent, filterPending] : undefined
              }
            >
              <Corrector.Filter>
                <Input.Container>
                  <h2>Redações enviadas</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Redações enviadas"
                      value={filterEssaySent?.toString() ?? ''}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterEssaySent(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
                <Input.Container>
                  <h2>Correções pendentes</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Redações pendentes"
                      value={filterPending?.toString()}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterPending(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
              </Corrector.Filter>
            </FilterComponent>

            <Corrector.Table>
              <TableHeader
                isStudentTable={isStudentTable}
                filter={filter}
                setFilter={setFilter}
                getIsAscending={getIsAscending}
              />
              <Corrector.TableBody>
                {sortTable(arrayFiltered, filter).map((content: any) => (
                  <TableItem
                    key={content.name + content.id}
                    content={content}
                    isStudent={isStudentTable}
                  />
                ))}
              </Corrector.TableBody>
            </Corrector.Table>
          </div>
        </Corrector.Background>
      )}
    </Layout>
  );
};

const Info = ({ label, text }: InfoProps) => (
  <Corrector.Info>
    <Corrector.Label>{label}</Corrector.Label>
    <Corrector.TextInfo>{text}</Corrector.TextInfo>
  </Corrector.Info>
);

const TableHeader = ({
  filter,
  setFilter,
  getIsAscending,
  isStudentTable,
}: TableHeadProps) => {
  const theadStudent: TableHeaderObj[] = [
    { name: 'Nome do aluno', sort: 'name' },
    { name: 'Redações enviadas', sort: 'essays', center: true },
    { name: 'Redações pendentes', sort: 'pending', center: true },
    { name: 'Data de cadastro', sort: 'created_at', center: true },
    { name: 'Status da solicitação', sort: 'status', center: true },
  ];
  const theadCorrections: TableHeaderObj[] = [
    { name: 'Título', sort: 'title' },
    { name: 'Nome do aluno', sort: 'name' },
    { name: 'Tema de redação', sort: 'theme' },
    { name: 'Data de envio', sort: 'created_at', center: true },
    { name: 'Status', sort: 'status', center: true },
  ];

  const thead = isStudentTable ? theadStudent : theadCorrections;

  return (
    <Corrector.TableHead>
      <Corrector.TR>
        {thead.map(({ name, sort, center }) => {
          const { prop, isAscending } = filter;
          return (
            <Corrector.TH key={name} center={center}>
              <div
                onClick={() => {
                  if (sort === prop) {
                    return setFilter({ prop, isAscending: !isAscending });
                  }
                  setFilter({ prop: sort as Props, isAscending: false });
                }}
              >
                {name}
                <Corrector.SvgArrow
                  path={Svg.ARROW_DOWN}
                  prop={getIsAscending(sort)}
                />
              </div>
            </Corrector.TH>
          );
        })}
      </Corrector.TR>
    </Corrector.TableHead>
  );
};

const TableItem = ({ content, isStudent }: any) => {
  const history = useHistory();
  const getEssayStatus = (status: number): string => {
    if (status === 1) {
      return 'A Corrigir';
    }
    return status === 2 ? 'Corrigindo' : 'Corrigida';
  };
  return isStudent ? (
    <Corrector.TR>
      <Corrector.TD>{content.name}</Corrector.TD>
      <Corrector.TD center>{content.essays}</Corrector.TD>
      <Corrector.TD center>{content.pending}</Corrector.TD>
      <Corrector.TD center>{formatDate(content.created_at)}</Corrector.TD>
      <Corrector.TD center>
        {content.status ? 'Aprovado' : 'Pendente'}
      </Corrector.TD>
      <Corrector.TD center>
        <Corrector.SvgApproved path={Svg.APPROVED} />
      </Corrector.TD>
    </Corrector.TR>
  ) : (
    <Corrector.TR onClick={() => history.push(`/essays-theme/${content.id}`)}>
      <Corrector.TD>{content.title}</Corrector.TD>
      <Corrector.TD>{content.name}</Corrector.TD>
      <Corrector.TD>{content.theme}</Corrector.TD>
      <Corrector.TD center>{formatDate(content.created_at)}</Corrector.TD>
      <Corrector.TD center>{getEssayStatus(content.status)}</Corrector.TD>
      <Corrector.TD center>
        <Corrector.Square
          color={getColorBasedStatus(getEssayStatus(content.status))}
        />
      </Corrector.TD>
    </Corrector.TR>
  );
};

export default Teacher;
