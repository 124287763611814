import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../../containers/Layout';
import api from '../../services/api/index';
import TablePaginationHeader from '../../components/TablePaginationHeader';
import { handleDateCommentLecture } from '../../utils/functions/Date';
import { showMessage } from '../../hooks/messages';
import { ILecture } from '../../domain/lecture';
import { ILectureComment } from '../../domain/lecture-comment';
import { ILectureRatings } from '../../domain/lecture-rating';
import { ILectureMaterial } from '../../domain/lecture-material';
import { ILectureVideo } from '../../domain/lecture-video';
import BreadCrumbs from '../../components/BreadCrumbs';
import * as Lecture from './styles';
import * as Svg from '../../utils/constants/svgs';

interface ParamTypes {
  id: string;
}
type InfoProps = { label: string; text: string };
type CardProps = { label: string; text: string };
type MaterialProps = { text: string; material: ILectureMaterial };

const initialVideo = {
  url: '',
};

const initialLecture = {
  id: 0,
  name: '',
  info: '',
  id_class: [
    {
      name: '',
    },
  ],
  id_menu: {
    name: '',
  },
  id_taxonomy: {
    knowledge_area: '',
  },
  id_tag: {
    tag: '',
  },
};

const ClassDashboard = () => {
  const PAGINATION_LIMIT = 8;
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startOffset, setStartOffset] = useState(1);
  const [pages, setPages] = useState<number[]>([]);
  const [rating, setRating] = useState<ILectureRatings[]>([]);
  const [materials, setMaterials] = useState<ILectureMaterial[]>([]);
  const [exercises, setExercises] = useState<ILectureMaterial[]>([]);
  const [comments, setComments] = useState<ILectureComment[]>([]);
  const [complaints, setComplaints] = useState<ILectureComment[]>([]);
  const [isAscending, setIsAscending] = useState(false);
  const [lecture, setLecture] = useState<ILecture | typeof initialLecture>(
    initialLecture
  );
  const [video, setVideo] = useState<ILectureVideo | typeof initialVideo>(
    initialVideo
  );

  const handleChangeCurrentPage = (current: number) => setCurrentPage(current);

  useEffect(() => {
    setStartOffset((currentPage - 1) * (PAGINATION_LIMIT - complaints.length));
  }, [currentPage, complaints]);

  useEffect(() => {
    Promise.all([
      api.get(`/lectures/${id}`).then((response) => {
        setLecture(response.data);
      }),
      api.get(`/lecture-materials?id_lecture=${id}`).then((response) => {
        const { data } = response;
        setMaterials(data);
      }),
      api.get(`/lecture-ratings?id_lecture=${id}`).then((response) => {
        const { data } = response;
        setRating(data);
      }),
      api.get(`/lecture-exercises?id_lecture=${id}`).then((response) => {
        const { data } = response;
        setExercises(data);
      }),
      api.get(`/lecture-videos?id_lecture=${id}`).then((response) => {
        const { data } = response;
        setVideo(data[0]);
      }),
      api.get(`/lecture-comments?id_lecture=${id}`).then((response) => {
        const { data } = response;
        const commentsWithoutComplaints = data.filter(
          (comment: any) => !comment.complaint
        );
        const commentsComplaints = data.filter(
          (comment: any) => !!comment.complaint
        );

        const totalPages = Math.ceil(data.length / PAGINATION_LIMIT);
        const newPages = Array.from({ length: totalPages }, (_, i) => i + 1);
        setTotal(data.length);
        setPages(newPages);
        setComplaints(commentsComplaints);
        setComments(commentsWithoutComplaints);
      }),
    ])
      .then(() => setLoading(false))
      .catch((err) => {
        showMessage({
          intent: 'error',
          message: 'Não foi possivel obter todos os dados relacionados a aula',
        });
        setTimeout(() => history.push('/lectures-dashboard/1'), 3000);
      });
  }, [id, history]);

  comments.sort((a, b) => {
    if (a.created_at < b.created_at) {
      return isAscending ? -1 : 1;
    }
    if (a.created_at > b.created_at) {
      return isAscending ? 1 : -1;
    }
    return 0;
  });

  const removeComment = async (id: number) => {
    await api
      .delete(`/lecture-comments/${id}`)
      .then((response) => {
        setComments(comments.filter((c) => c.id !== response.data.id));
        showMessage({
          intent: 'success',
          message: 'Comentário excluido com sucesso',
        });
      })
      .catch(() =>
        showMessage({
          intent: 'error',
          message: 'Ocorreu um erro durante a remoção do comentário',
        })
      );
  };

  const getAverageRating = (): string => {
    const ratingsArray = rating.map((e) => e.rating);
    const allRatings =
      ratingsArray.length && ratingsArray.reduce((acc, curr) => acc + curr);
    const average = allRatings / rating.length;
    const number = average ? average.toFixed(1) : 0;
    return number.toString();
  };

  return (
    <Layout>
      {loading ? (
        <Lecture.Loading />
      ) : (
        <Lecture.Background>
          <BreadCrumbs
            crumbs={[
              {
                name: 'Cursinho',
                redirect: '/lectures-dashboard/1',
              },
              { name: lecture.name, active: true },
            ]}
          />
          <Lecture.Title>{lecture.name}</Lecture.Title>
          <Lecture.Link target="_blank" href={video.url}>
            <Lecture.ArrowLink>
              Assistir aula
              <Lecture.Arrow>
                <Lecture.SvgArrow path={Svg.ARROW_DOWN} />
              </Lecture.Arrow>
            </Lecture.ArrowLink>
          </Lecture.Link>
          <div>
            <Lecture.Subtitle>Detalhes</Lecture.Subtitle>
            <Lecture.CardInfo>
              <Lecture.InfoContent>
                <Info label="Grande área" text={lecture.id_menu.name} />
                <Info label="Área" text={lecture.id_taxonomy.knowledge_area} />
                <Info label="Disciplina" text={lecture.id_class[0].name} />
                <Info label="Frente" text={lecture.id_tag.tag} />
              </Lecture.InfoContent>
              <Lecture.InfoContentBottom>
                <Info label="info" text={lecture.info || ''} />
              </Lecture.InfoContentBottom>
            </Lecture.CardInfo>
            <Lecture.Cards>
              <Card
                label="Comentários"
                text={(complaints.length + comments.length).toString()}
              />
              <Card label="Avaliações" text={rating.length.toString()} />
              <Card label="Avaliação média" text={getAverageRating()} />
            </Lecture.Cards>
          </div>
          <Lecture.Subtitle>Materiais</Lecture.Subtitle>
          <Lecture.AllMaterials>
            {materials.map((material) => (
              <Material
                key={'Slide' + material.id}
                text="Slide da aula"
                material={material}
              />
            ))}
            {exercises.map((exercise) => (
              <Material
                key={'exercícios' + exercise.id}
                text="Lista de exercícios"
                material={exercise}
              />
            ))}
          </Lecture.AllMaterials>
          <div>
            <Lecture.CommentHeader isAscending={isAscending}>
              <Lecture.Subtitle>Comentários </Lecture.Subtitle>
              <Lecture.Button onClick={() => setIsAscending(!isAscending)}>
                {isAscending ? 'Mais antigos' : 'Mais recentes'}
                <Lecture.SvgToggle path={Svg.TOGGLE} />
              </Lecture.Button>
            </Lecture.CommentHeader>
            <div>
              {complaints.map((comment: ILectureComment) => {
                const stars = rating.find(
                  (r: any) => r.id_student === comment.id_users[0]?.id
                )?.rating;
                return (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    stars={stars || 0}
                    remove={removeComment}
                  />
                );
              })}
              {comments
                .slice(
                  startOffset,
                  startOffset + PAGINATION_LIMIT - complaints.length
                )
                .map((comment: ILectureComment) => {
                  const stars = rating.find(
                    (r: any) => r.id_student === comment.id_users[0]?.id
                  )?.rating;
                  return (
                    <Comment
                      key={comment.id}
                      comment={comment}
                      stars={stars || 0}
                      remove={removeComment}
                    />
                  );
                })}
            </div>
            <TablePaginationHeader
              limit={PAGINATION_LIMIT}
              pages={pages}
              totalItems={total}
              currentPage={currentPage}
              handleClick={handleChangeCurrentPage}
              label="comentários"
            />
          </div>
        </Lecture.Background>
      )}
    </Layout>
  );
};

type CommentProps = {
  comment: ILectureComment;
  stars: number;
  remove: (id: number) => Promise<void>;
};

const Comment = ({ comment, stars, remove }: CommentProps) => {
  const name = comment.id_users[0]?.name;
  const like = comment.like?.split(',').length;
  const starCount = [...Array(stars || 0)];
  const complaint = !!comment.complaint;
  return (
    <Lecture.Comment key={comment.id} isComplaint={complaint}>
      <Lecture.CommentContent>
        <div>
          <h2>{name}</h2>
          <div>
            {starCount.map((_, index) => (
              <Lecture.SvgStar key={index} path={Svg.STAR_WITHOUT_COLOR} />
            ))}
          </div>
        </div>
        <div>
          {complaint ? (
            <Lecture.Complaint>Comentário abusivo</Lecture.Complaint>
          ) : (
            <>
              <Lecture.SvgLike path={Svg.LIKE} />
              {like}
            </>
          )}
          <div onClick={() => remove(comment.id)}>
            <Lecture.SvgRemove path={Svg.TRASH} isComplaint={complaint} />
          </div>
        </div>
      </Lecture.CommentContent>
      <label>{handleDateCommentLecture(comment.created_at)}</label>
      <p>{comment.comment}</p>
    </Lecture.Comment>
  );
};

const Info = ({ label, text }: InfoProps) => (
  <Lecture.Info>
    <Lecture.InfoLabel>{label}</Lecture.InfoLabel>
    <Lecture.InfoText>{text}</Lecture.InfoText>
  </Lecture.Info>
);

const Card = ({ label, text }: CardProps) => (
  <Lecture.Card>
    <Lecture.CardContentFirst>
      <Lecture.CardText>
        {text}
        {label === 'Avaliação média' && <Lecture.CardSpan>/5</Lecture.CardSpan>}
      </Lecture.CardText>
    </Lecture.CardContentFirst>
    <Lecture.CardContent>
      <Lecture.SvgIcon
        path={label === 'Comentários' ? Svg.SMALL_EYE : Svg.STAR_WITHOUT_COLOR}
      />
      {label}
    </Lecture.CardContent>
  </Lecture.Card>
);

const Material = ({ text, material }: MaterialProps) => (
  <Lecture.CardMaterial href={material.url} target="_blank" key={material.id}>
    <Lecture.SvgDocument path={Svg.DOCUMENT} />
    <Lecture.MaterialContent>
      <Lecture.MaterialText>{text}</Lecture.MaterialText>
      <Lecture.MaterialSpan>Abrir arquivo</Lecture.MaterialSpan>
    </Lecture.MaterialContent>
  </Lecture.CardMaterial>
);

export default ClassDashboard;
