import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { UserProps } from '../../domain/user';
import { verifyPassword } from '../../hooks/auth';
import { showMessage } from '../../hooks/messages';
import { updateTeacherPassword } from '../../hooks/user';
import { InputPassord } from '../../components/InputHooks';
import api from '../../services/api';
import * as M from './styles';
import * as Svg from '../../utils/constants/svgs';

type ModalProps = {
  show: boolean;
  children: any;
};

type ModalComponentProp = {
  id: number;
  groupId?: number;
  users?: boolean;
  closeModal: () => void;
};

type FormValues = {
  password: string;
  confirmPassword: string;
};

export default function Modal({ show, children }: ModalProps) {
  return (
    <M.Background show={show}>
      <M.Content>{children}</M.Content>
    </M.Background>
  );
}

type HeaderProps = {
  children: React.ReactNode;
  close: () => void;
};

Modal.Header = function ModalHeader({ children, close }: HeaderProps) {
  return (
    <M.Header>
      <M.Title>{children}</M.Title>
      <div onClick={() => close()}>
        <M.Close path={Svg.CLOSE} />
      </div>
    </M.Header>
  );
};

Modal.Delete = function ModalDelete({
  id,
  groupId,
  users,
  closeModal,
}: ModalComponentProp) {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { password: '' },
  });

  const close = () => {
    setValue('password', '');
    setStep(1);
    closeModal();
  };

  const submit = async (data: FormValues) => {
    if (users) {
      showMessage({
        intent: 'error',
        message: 'Não é possivel excluir um corretor com alunos vinculados.',
      });
      return;
    }
    if (step === 1) {
      setStep(2);
      return;
    }
    const response = await verifyPassword(data.password);
    if (response?.status === 'success') {
      Promise.all([
        api.delete(`/users/${id}`),
        groupId &&
          api.delete(`/essay-groups/${groupId}`).then(({ data }) => {
            data.users.forEach((user: UserProps) =>
              api.put(`/users/${user.id}`, { group: null })
            );
            data.essays.forEach((essay: any) =>
              api.put(`/essays/${essay.id}`, { teacher: null })
            );
          }),
      ])
        .then(() => {
          history.push('/correctors');
        })
        .catch(() =>
          showMessage({
            intent: 'error',
            message: 'Ocorreu um erro durante a exclusão do professor.',
          })
        );
    }
    response &&
      showMessage({
        intent: response.status,
        message: response.message,
      });
    return;
  };

  return (
    <M.Form onSubmit={handleSubmit(submit)}>
      <M.Paragraph>
        Ao excluir este corretor será necessário realizar um novo cadastro caso
        deseje inserí-lo na plataforma novamente.
      </M.Paragraph>
      {step === 2 ? (
        <>
          <M.Paragraph>Deseja excluir mesmo assim?</M.Paragraph>
          <InputPassord
            label="Digite sua senha para confirmar"
            placeholder="*****"
            {...register('password', {
              required: 'Senha de confirmação é obrigatória',
            })}
            error={errors.password?.message}
          />
        </>
      ) : null}

      <M.Buttons>
        {step === 2 ? (
          <M.Cancel onClick={() => close()}>Cancelar</M.Cancel>
        ) : null}
        <M.Save type="submit">
          {step === 1 ? 'Entendi' : 'Salvar alteração'}
        </M.Save>
      </M.Buttons>
    </M.Form>
  );
};

Modal.Password = function ModalPassword({
  id,
  closeModal,
}: ModalComponentProp) {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { password: '', confirmPassword: '' },
  });

  const close = () => {
    setValue('password', '');
    setValue('confirmPassword', '');
    closeModal();
  };

  const submit = async (data: FormValues) => {
    const response = await updateTeacherPassword({
      id: id.toString(),
      password: data.password,
    });
    response &&
      showMessage({ intent: response.status, message: response.message });
    if (response?.status === 'success') {
      close();
    }
    return;
  };

  return (
    <M.Form onSubmit={handleSubmit(submit)}>
      <InputPassord
        label="Nova senha"
        placeholder="Insira a nova senha deste professor"
        {...register('password', {
          required: 'Nova senha é um campo obrigatório',
        })}
        error={errors.password?.message}
      />
      <InputPassord
        placeholder="Confirme a nova senha deste professor"
        label="Confirmar nova senha"
        {...register('confirmPassword', {
          required: 'Confirmar senha é um campo obrigatório.',
          validate: {
            passwordIsEqual: (value: string) => {
              const { password } = getValues();
              return password === value || 'As senhas não são iguais';
            },
          },
        })}
        error={errors.confirmPassword?.message}
      />
      <M.Buttons>
        <M.Cancel onClick={() => close()}>Cancelar</M.Cancel>
        <M.Save type="submit">Salvar alteração</M.Save>
      </M.Buttons>
    </M.Form>
  );
};
