import styled from '@emotion/styled';
import LoadingComponent from '../../components/Loading';
import Svg from '../../components/Svg';

type BooleanProps = {
  center?: boolean;
  prop?: boolean;
};

type ColorProps = {
  color: string;
};

type ItemProps = {
  selected?: boolean;
};

export const Loading = styled(LoadingComponent)`
  padding: 30vh 0px;
`;

export const Background = styled.div`
  width: 100%;
  min-height: 60vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 0px auto;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  margin: 0.5rem 0px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 410px;
  width: min-content;
`;

export const Subtitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.h2`
  color: #050a24;
  font-size: 1.2rem;
`;

export const Delete = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  padding: 0.5rem;
  color: #e25d5d;
  cursor: pointer;
  transition: 200ms;
  border-radius: 5px;
  :hover {
    background: #e25d5d;
    color: #fff;
    box-shadow: 0px 4px 12px rgba(5, 10, 36, 0.05);
  }
`;

export const Card = styled.div`
  background: #fff;
  border: 0.5px solid #f2f2f2;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(5, 10, 36, 0.05);
  width: 100%;
  padding: 1rem;
  margin-top: 0.5rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 0.5rem;
`;

export const Label = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
  color: #a8aabc;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

export const TextInfo = styled.p`
  font-size: 1rem;
  color: #444655;
`;

export const DoubleInfo = styled.div`
  display: flex;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 0px;
  border: 0.5px solid #aeb3c8;
  border-radius: 5px;
  color: #3e79c4;
  background: #fff;
  transition: 200ms;
  font-weight: bold;
  margin-top: 0.5rem;
  :hover {
    color: #fff;
    background: #3e79c4;
  }
`;

export const Toggle = styled.div<ItemProps>`
  display: flex;
  margin: 2rem 0px;
`;

export const ToggleButton = styled.div<ItemProps>`
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-right: 1rem;
  font-weight: bold;
  color: ${({ selected }) => (selected ? '#050a24' : '#a8aabc')};
  border-bottom: ${({ selected }) => (selected ? '2px solid #38b6ff' : 'none')};
  padding-bottom: ${({ selected }) => (selected ? '5px' : '7px')};
`;

export const ToggleLabel = styled.label`
  cursor: pointer;
  letter-spacing: 0px;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const TableBody = styled.tbody`
  tr:nth-of-type(odd) {
    background-color: #fafbfb;
  }
`;

export const TableHead = styled.thead`
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
    grid-gap: 0.2rem;
    align-items: center;
    background-color: #fafbfb;
    border-radius: 5px;
    padding: 0.5rem 10px;
    background-color: #f4f4f5;
    th {
      height: 40px;
    }
  }
`;

export const TH = styled.th<BooleanProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'initial')};
  font-size: 1.2rem;
  max-width: 250px;
  cursor: pointer;
`;

export const SvgArrow = styled(Svg)<BooleanProps>`
  margin-left: 5px;
  width: 13px;
  height: 10px;
  transform: ${({ prop }) => (prop ? 'unset' : 'rotateZ(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

export const TR = styled.tr`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px;
  grid-gap: 0.2rem;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem 10px;
  td:first-of-type {
    svg {
      margin: 0 5px;
      width: 20px;
      height: 20px;
      fill: #ffd600;
    }
  }
`;

export const TD = styled.td<BooleanProps>`
  max-width: 250px;
  height: 40px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #050a24;
  overflow: hidden;
  justify-content: ${({ center }) => (center ? 'center' : 'initial')};
  div {
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }
  span {
    display: flex;
    height: 100%;
    overflow: hidden;
    color: #56b4ea;
    align-items: baseline;
    line-height: 40px;
    word-break: break-all;
  }
`;

export const SvgApproved = styled(Svg)`
  margin: 0 5px;
  width: 25px;
  height: 22px;
  fill: #a8aabc;
`;

export const Square = styled.div<ColorProps>`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  div:first-of-type {
    margin-right: 2rem;
  }
  input {
    width: 100%;
    height: 32px;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    ::placeholder {
      color: #a8aabc;
      opacity: 1;
    }
  }
`;
