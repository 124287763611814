import styled from '@emotion/styled';

interface PageProps {
  isSelect?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 0.8rem;
  line-height: 20px;
  color: #9aa0ba;
  margin-top: 2%;
  font-size: 0.9rem;
  margin-bottom: 1%;
  div {
    display: flex;
    width: 40%;
    justify-content: flex-end;
    div {
      align-items: center;
      justify-content: center;
      width: 15px;
      margin: 0 5px;
      height: 20px;
    }
  }
`;

export const PaginationItem = styled.div<PageProps>`
  margin: 0;
  margin-right: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.isSelect && {
      color: 'white',
      background: '#0E57B5',
      padding: '0 10px',
    }}
`;

export const Point = styled.div`
  width: 10px;
  padding-top: 10px;
`;
