import { FormEvent, useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Back from '../../components/Back';
import { useHistory } from 'react-router-dom';
import { Context, Content, Container, Cancel } from './styles';
import { forgetPassword } from '../../hooks/auth';

const ForgotPassword = () => {
  const history = useHistory();
  const [form, setForm] = useState({
    email: '',
  });

  const handleChange = (field: string | number | symbol) => ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((previousForm) => ({ ...previousForm, [field]: value }));
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    await forgetPassword(form.email);
    history.push('/');
  };

  return (
    <Context>
      <Content
        onClick={() => {
          history.goBack();
        }}
      >
        <Back />
      </Content>
      <Container>
        <h2>Informe seu email para a recuperação de sua senha</h2>
        <p>
          Você receberá no seu email um link para a definição de uma nova senha.
        </p>
        <form onSubmit={submit}>
          <Input
            placeholder="Digite aqui seu email"
            labelText="Email"
            name="email"
            type="email"
            change={handleChange}
          />
          <Button type="submit" text="Recuperar senha" />
        </form>
        <Cancel href="/">Cancelar</Cancel>
      </Container>
    </Context>
  );
};
export default ForgotPassword;
