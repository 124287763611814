import api from './../services/api/index';
import { showMessage } from './messages';
import { renderError } from './error';
import { PORTAL_STUDENT } from '../config/urls/portal';
type NewTeacherEmailProps = {
  teacherName: string;
  code: string;
  email: string;
  link: string;
};

type acceptedInEssayAreaEmailProps = {
  name: string;
  urlEssay?: string;
  email: string;
};

export const newTeacherEmail = async ({
  teacherName,
  code,
  email,
  link,
}: NewTeacherEmailProps) => {
  try {
    await api.post('/newTeacher', {
      teacherName,
      code,
      email,
      link,
    });
    showMessage({ intent: 'success', message: 'E-mail enviado com sucesso!' });
  } catch (err) {
    renderError(err);
    showMessage({ intent: 'error', message: 'Erro ao enviar e-mail' });
  }
};

export const acceptedInEssayAreaEmail = async ({
  name,
  urlEssay,
  email,
}: acceptedInEssayAreaEmailProps) => {
  try {
    await api.post('/accepedtInEssayArea', {
      name,
      urlEssay: urlEssay ? urlEssay : PORTAL_STUDENT,
      email,
    });
    showMessage({ intent: 'success', message: 'E-mail enviado com sucesso!' });
  } catch (err) {
    showMessage({ intent: 'error', message: 'Erro ao enviar e-mail' });
    renderError(err);
  }
};
