import styled from '@emotion/styled';
import Svg from '../../components/Svg';

type ModalProps = {
  show: boolean;
};

export const Background = styled.div<ModalProps>`
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 11;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: rgba(5, 10, 36, 0.2);
`;

export const Content = styled.div`
  background: #fff;
  box-shadow: 0px 4px 12px rgba(5, 10 36, 0.05);
  border-radius: 5px;
  padding: 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

export const Title = styled.h2`
  color: #444655;
  font-size: 1.3rem;
  font-weight: bold;
`;

export const Close = styled(Svg)`
  width: 15px;
  height: 15px;
  fill: #a8aabc;
  cursor: pointer;
`;

export const Form = styled.form`
  padding: 0.5rem;
  width: 500px;
`;

export const Paragraph = styled.p`
  color: #444655;
  font-size: 1rem;
  margin-bottom: 2rem;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 1rem;
`;

export const Cancel = styled.div`
  border-radius: 5px;
  background: transparent;
  color: #444655;
  border: none;
  box-shadow: none;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 2rem;
  cursor: pointer;
`;

export const Save = styled.button`
  border-radius: 5px;
  background: #0e57b5;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
`;
