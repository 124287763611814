import { Container } from './styles';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
  const history = useHistory();
  history.goBack();
  return <Container>Not Found</Container>;
};

export default NotFound;
