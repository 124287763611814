import styled from '@emotion/styled';

export const Container = styled.button`
  width: 100%;

  height: 65px;
  background: #0e57b5;
  border-radius: 5px;
  border: #0e57b5;
  color: white;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 10px 0px;
  &:hover {
    background: #0e57e1;
    cursor: pointer;
  }
`;
