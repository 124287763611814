import { useState, useEffect } from 'react';
import TablePaginationHeader from '../../components/TablePaginationHeader';
import Layout from '../../containers/Layout';
import api from '../../services/api';
import { PAGINATION_LECTURES_LIMIT } from '../../config/lecture/table';
import { useParams, useHistory } from 'react-router-dom';
import { ILectureRatings } from '../../domain/lecture-rating';
import { ILectureComment } from '../../domain/lecture-comment';
import { ILectureVideo } from '../../domain/lecture-video';
import { showMessage } from '../../hooks/messages';
import { ITaxonomy } from '../../domain/taxonomy';
import { ILecture } from '../../domain/lecture';
import { IClass } from '../../domain/class';
import { IMenu } from '../../domain/menu';
import { Dashboard, CardsProps } from '../../components/Dashboard';
import FilterComponent from '../../components/FilterComponent';
import SelectDashboard from '../../components/SelectDashboard';
import * as DB from './styles';
import * as Svg from '../../utils/constants/svgs';
import { sortTable } from '../../utils/functions/Table/table-functions';

interface ILectureToShow extends ILecture {
  rating: number;
  ratings: number;
  link: string;
  complaint: boolean;
}

interface ParamTypes {
  id: string;
}

type Props = 'rating' | 'ratings' | 'name' | 'id_class' | 'link' | 'created_at';

type SelectProps = { id: number | null; name: string };

type TableHeaderObj = {
  name: string;
  sort: string;
};

type TableHeadProps = {
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
  thead: TableHeaderObj[];
  getIsAscending: (prop: string) => boolean;
};

type FilterProps = {
  prop: Props;
  isAscending: boolean;
};

const DashboardLectures = () => {
  const thead: TableHeaderObj[] = [
    { name: 'Nota', sort: 'rating' },
    { name: 'Avaliações', sort: 'ratings' },
    { name: 'Título', sort: 'name' },
    { name: 'Área', sort: 'id_class' },
    { name: 'Vídeo da aula', sort: 'link' },
  ];
  const { id } = useParams<ParamTypes>();
  const history = useHistory();
  const [menu, setMenu] = useState<IMenu | null>(null);
  const [startOffset, setStartOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [lectures, setlectures] = useState<ILectureToShow[]>([]);
  const [lectureVideos, setLectureVideos] = useState<ILectureVideo[]>([]);
  const [comments, setComments] = useState<ILectureComment[]>([]);
  const [taxonomy, setTaxonomy] = useState<ITaxonomy[]>([]);
  const [classes, setClasses] = useState<IClass[]>([]);
  const [totalPage, setTotalPage] = useState<number[]>([]);
  const [ratings, setRatings] = useState<ILectureRatings[]>([]);
  const [notes, setNote] = useState('0');
  const [page, setPage] = useState(1);
  const [evaluated, setEvaluated] = useState(0);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filterClasses, setFilterClasses] = useState<SelectProps>({
    id: null,
    name: 'Selecionar',
  });
  const [filterTaxonomy, setFilterTaxonomy] = useState<SelectProps>({
    id: null,
    name: 'Selecionar',
  });
  const [filter, setFilter] = useState<FilterProps>({
    prop: 'rating',
    isAscending: false,
  });

  useEffect(() => {
    setTaxonomy([]);
    setIsTableLoading(true);
    setSearch('');
    Promise.all([
      api.get(`/menus/${id}`).then(({ data }) => {
        let taxonomy = data.taxonomies;
        api.get(`/classes`).then(({ data }) => {
          const alltaxonomy = taxonomy.map((t: ITaxonomy) => t.knowledge_area);
          const filteredClasses = data.filter((c: IClass) => {
            return alltaxonomy.includes(c.id_taxonomy[0].knowledge_area);
          });
          setClasses(filteredClasses);
        });
        setMenu(data);
        setTaxonomy(data.taxonomies);
      }),
    ]).catch(() => {
      showMessage({
        intent: 'error',
        message: 'Não foi possivel encontrar a grande área',
      });
      setTimeout(() => history.goBack(), 3000);
    });
  }, [id, history]);

  useEffect(() => {
    Promise.all([
      api.get(`/lectures/count?id_menu=${id}`).then(({ data }) => {
        setTotal(data);
      }),
      api.get(`/lectures?id_menu=${id}`).then(({ data }) => {
        setlectures(data);
        setIsTableLoading(false);
        const totalPages = Math.round(data.length / PAGINATION_LECTURES_LIMIT);
        const arrayPages = Array.from({ length: totalPages }, (_, i) => i + 1);
        setTotalPage(arrayPages);
      }),
      api
        .get(`/lecture-ratings?_where[id_lecture.id_menu]=${id}`)
        .then(({ data }) => {
          setRatings(data);
          let notes = 0;
          for (const { rating } of data) {
            if (rating) notes += rating;
          }
          const totalRating = data.length > 0 ? data.length : 1;
          setNote((notes / totalRating).toFixed(1));
        }),
      api
        .get(`/lecture-ratings/count?_where[id_lecture.id_menu]=${id}`)
        .then(({ data }) => {
          setEvaluated(data);
        }),
      api.get(`/lecture-videos`).then(({ data }) => setLectureVideos(data)),
      api.get(`/lecture-comments`).then(({ data }) => setComments(data)),
    ]).catch(() => {
      showMessage({
        intent: 'error',
        message: 'Erro ao buscar dados',
      });
    });
  }, [id]);

  const handleChangePage = (page: number) => {
    setPage(page);
    setStartOffset((page - 1) * PAGINATION_LECTURES_LIMIT);
  };

  const getIsAscending = (value: string): boolean => {
    return filter.prop === value && filter.isAscending === true;
  };

  const formatLecture = () => {
    const lectureFiltered = lectures.filter((l) => {
      if (filterClasses.id || filterTaxonomy.id) {
        return (
          l.id_class[0].id === filterClasses.id ||
          l.id_taxonomy?.id === filterTaxonomy.id
        );
      }
      return true;
    });
    return lectureFiltered.map((l) => {
      const link =
        lectureVideos.find((link) => link?.id_lecture[0].id === l.id)?.url ||
        '';
      const complaint = comments.some(
        (c) => c.id_lecture.id === l.id && c.complaint
      );

      const allRating = ratings
        .filter((r: ILectureRatings) => r.id_lecture.id === l.id)
        .map((r) => r.rating);

      const rating = allRating.length
        ? (
            allRating.reduce((acc, curr): number => acc + curr) /
            allRating.length
          ).toFixed(1)
        : 0;

      return {
        ...l,
        id_class: l.id_class[0].name,
        ratings: allRating.length,
        rating,
        link,
        complaint,
      };
    });
  };

  const arrayFiltered = formatLecture().filter(
    (elem) =>
      elem.name.toLowerCase().includes(search.toLowerCase()) ||
      elem.id_class.toLowerCase().includes(search.toLowerCase())
  );

  const cards: CardsProps[] = [
    {
      label: 'Aulas disponíveis',
      text: total.toString(),
      svg: Svg.PLAYER,
    },
    {
      label: 'Aulas avaliadas',
      text: evaluated.toString(),
      svg: Svg.STAR_WITHOUT_COLOR,
    },
    {
      label: 'Avaliação total',
      text: notes,
      svg: Svg.STAR_WITHOUT_COLOR,
      subinfo: '/5',
    },
  ];

  return (
    <Layout>
      <DB.Container>
        <Dashboard
          title={menu?.name || ''}
          subtitle="Dashboard das aulas"
          cards={cards}
        />
        {isTableLoading ? (
          <DB.Loading />
        ) : (
          <>
            <FilterComponent
              menu="lectures"
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              filters={[filterTaxonomy.id, filterClasses.id]}
            >
              <DB.Filter>
                <SelectDashboard
                  text="Área"
                  options={classes.map((c) => ({
                    id: c.id,
                    name: c.name,
                  }))}
                  value={filterClasses.name}
                  setValue={setFilterClasses}
                />
                <SelectDashboard
                  text="Grande área"
                  options={taxonomy.map((t) => ({
                    id: t.id,
                    name: t.knowledge_area,
                  }))}
                  value={filterTaxonomy.name}
                  setValue={setFilterTaxonomy}
                />
              </DB.Filter>
            </FilterComponent>
            <DB.Table>
              <TableHeader
                thead={thead}
                filter={filter}
                setFilter={setFilter}
                getIsAscending={getIsAscending}
              />
              <DB.TableBody>
                {sortTable(arrayFiltered, filter)
                  .slice(startOffset, startOffset + PAGINATION_LECTURES_LIMIT)
                  .map((lecture: ILectureToShow) => (
                    <TableItem key={lecture.id} lecture={lecture} />
                  ))}
              </DB.TableBody>
            </DB.Table>
            <TablePaginationHeader
              pages={totalPage}
              limit={PAGINATION_LECTURES_LIMIT}
              totalItems={total}
              currentPage={page}
              handleClick={handleChangePage}
              label="aulas"
            />
          </>
        )}
      </DB.Container>
    </Layout>
  );
};

const TableHeader = ({
  filter,
  setFilter,
  thead,
  getIsAscending,
}: TableHeadProps) => {
  return (
    <DB.TableHead>
      <DB.TR>
        {thead.map((head, index) => {
          const { name, sort } = head;
          const { prop, isAscending } = filter;
          return (
            <DB.TH key={index}>
              <div
                onClick={() => {
                  if (sort === prop) {
                    setFilter({ prop, isAscending: !isAscending });
                    return;
                  }
                  setFilter({ prop: sort as Props, isAscending: false });
                }}
              >
                {name}
                <DB.SvgArrow
                  path={Svg.ARROW_DOWN}
                  prop={getIsAscending(sort)}
                />
              </div>
            </DB.TH>
          );
        })}
      </DB.TR>
    </DB.TableHead>
  );
};

const TableItem = ({ lecture }: any) => {
  const history = useHistory();
  return (
    <DB.TR onClick={() => history.push(`/lecture/${lecture.id}`)}>
      <DB.TD>
        <DB.SvgIcon path={Svg.STAR_FULL_COLOR} />
        {lecture.rating}
      </DB.TD>
      <DB.TD>{lecture.ratings}</DB.TD>
      <DB.TD>{lecture.name}</DB.TD>
      <DB.TD>{lecture.id_class}</DB.TD>
      <DB.TD>
        <span>{lecture.link}</span>
      </DB.TD>

      <DB.TD>
        {lecture.complaint ? <DB.SvgIcon path={Svg.WARNING} /> : null}
      </DB.TD>
    </DB.TR>
  );
};
export default DashboardLectures;
