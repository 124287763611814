import Layout from '../../containers/Layout';
import { Content, Option } from './styles';
import OptionItem from '../../components/OptionItem';
import { Link } from 'react-router-dom';
import ThemeEssayContainer from '../../containers/ThemeEssay';
function EssayPage() {
  return (
    <Layout>
      <Content>
        <Option>
          <OptionItem selected>
            <Link to="/essays">Redações</Link>
          </OptionItem>
          <OptionItem>
            <Link to="/new-theme">Novo tema</Link>
          </OptionItem>
        </Option>

        <ThemeEssayContainer />
      </Content>
    </Layout>
  );
}

export default EssayPage;
