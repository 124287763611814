import { Container, HiddenCheck, Check } from './styles';

interface CheckboxProps {
  isChecked: boolean;
  change: Function;
}

const Checkbox = ({ change, isChecked }: CheckboxProps) => {
  return (
    <Container>
      <HiddenCheck
        type="checkbox"
        checked={isChecked}
        onChange={() => change()}
      />
      <Check isChecked={isChecked}>
        {isChecked && (
          <svg>
            <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1.022,1.022,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16a1,1,0,0,0,0-1.47Z" />
          </svg>
        )}
      </Check>
    </Container>
  );
};

export default Checkbox;
