import styled from '@emotion/styled';

interface SelectProps {
  show: boolean;
  isGreyScale?: boolean;
}

export const Select = styled.div<SelectProps>`
  position: relative;
  cursor: pointer;
  margin-bottom: 22px;
  > label {
    font-weight: bold;
    margin-left: 0px;
    position: absolute;
    margin-top: 0.5rem;
    padding-left: 0.8rem;
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    opacity: 1;
    z-index: 2;
    color: #38b6ff;
  }

  > button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-height: 72px;
    background: transparent;
    color: #9298b5;
    border: 0.5px solid #9aa0ba;
    box-shadow: ${({ show }) =>
      show ? '0px 4px 4px 5px rgba(0, 0, 0, 0.05);' : 'none'};
    padding-top: 2.4rem;
    text-align: left;
    font-size: 1.05rem;
    padding-left: 0.8rem;
    letter-spacing: 0.8px;
    border-radius: 8px;
    z-index: 1;
    border-color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#38B6FF')};
    color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#050a24')};
  }
`;

export const Toggle = styled.svg<SelectProps>`
  height: 20px;
  width: 26px;
  fill: #6fcaff;
  margin-right: 1rem;
  transform: ${({ show }) =>
    show
      ? 'translateY(-9px) scale(1.2) rotateX(180deg)'
      : 'translateY(-12px) scale(1.2)'};
`;

export const Options = styled.div`
  min-height: 120px;
  width: 200px;

  display: flex;
  flex-direction: column;

  margin-top: 1px;
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #6fcaff;
  background-color: white;
`;

export const Option = styled.button`
  min-height: 50px;
  width: 190px;

  font-size: 0.9rem;

  padding: 3px;
  border-radius: 5px;
  text-align: start;
  background-color: white;
  border: none;

  &:hover {
    background-color: #ccc;
  }
`;
