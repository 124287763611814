import Layout from '../../containers/Layout';
import { Content, Option } from './styles';
import OptionItem from '../../components/OptionItem';
import TeacherForm from '../../containers/TeacherForm';
import { Link } from 'react-router-dom';

const NewTeacher = () => {
  return (
    <Layout>
      <Content>
        <Option>
          <OptionItem>
            <Link to="/teachers">Professores</Link>
          </OptionItem>
          <OptionItem selected>
            <Link to="/new-teacher">Novo cadastro</Link>
          </OptionItem>
        </Option>
        <TeacherForm />
      </Content>
    </Layout>
  );
};

export default NewTeacher;
