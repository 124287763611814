import { useState, useEffect } from 'react';
import { signOut } from '../../hooks/auth';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Logo from '../../images/logo.png';
import api from '../../services/api';
import { showMessage } from '../../hooks/messages';
import {
  Menu,
  Content,
  Left,
  Center,
  Right,
  Item,
  Select,
  Items,
  Svg,
} from './styles';
import { MENU_OUT_ICON, FILTER } from '../../utils/constants/svgs';

interface IMenu {
  id: number;
  name: string;
}

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [menu, setMenu] = useState<IMenu[]>([]);
  const dashboardRoute = ['lectures-dashboard', 'lecture'];
  const teacherRoute = ['correctors', 'corrector'];
  const studentsRoute = ['students'];
  const essayRoute = ['essays', 'theme'];

  useEffect(() => {
    Promise.all([
      api.get('/menus').then(({ data }) => {
        setMenu(data);
      }),
    ]).catch(() => {
      showMessage({
        intent: 'error',
        message: 'Erro ao buscar dados',
      });
    });
  }, []);

  function handleSelectedItem(paths: string[]): boolean {
    return !!paths.filter((path) => location.pathname.includes(path, 1)).length;
  }

  function renderItemMenu(
    label: string,
    route: string,
    selectedRoutes: string[]
  ) {
    return (
      <Item
        selected={handleSelectedItem(selectedRoutes)}
        onClick={() => {
          history.push(route);
        }}
      >
        <label>{label}</label>
      </Item>
    );
  }
  return (
    <Menu>
      <Content>
        <Left onClick={() => history.push('/lectures-dashboard/1')}>
          <img src={Logo} alt="logo" />
          <label>/admin</label>
        </Left>
        <Center>
          <Select>
            <Item
              selected={handleSelectedItem(dashboardRoute)}
              onClick={() => setIsSelectOpen(!isSelectOpen)}
            >
              <label>Grandes Áreas</label>
              <Svg show={isSelectOpen}>
                <path d={FILTER} />
              </Svg>
            </Item>
            <Items show={isSelectOpen}>
              {menu.map(({ id, name }) => (
                <button
                  key={id}
                  type="button"
                  onClick={() => {
                    setIsSelectOpen(!isSelectOpen);
                    history.push(`/lectures-dashboard/${id}`);
                  }}
                >
                  {name}
                </button>
              ))}
            </Items>
          </Select>
          {renderItemMenu('Corretores', '/correctors', teacherRoute)}
          {renderItemMenu('Alunos', '/students', studentsRoute)}
          {renderItemMenu('Redação', '/essays', essayRoute)}
        </Center>
        <Right
          onClick={() => {
            signOut();
            history.push('/');
          }}
        >
          <label>Sair</label>
          <svg>
            <path d={MENU_OUT_ICON} />
          </svg>
        </Right>
      </Content>
    </Menu>
  );
};

export default Header;
