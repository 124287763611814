type SvgProps = {
  className?: string;
  path: string;
};

const Svg = ({ className, path }: SvgProps) => (
  <svg className={className}>
    <path d={path} />
  </svg>
);

export default Svg;
