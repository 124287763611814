import api from '../services/api';
import { showMessage } from './messages';
import { renderError } from './error';

interface UserProps {
  id: number;
  username: string;
  name: string;
  email: string;
  course: string;
  school: string;
  age: string;
  group: number;
  role: string;
  platform: string;
  phone: string;
  area: string;
  password?: string;
}

interface LoginProps {
  identifier: string;
  password: string;
}

interface MessageProps {
  status: 'success' | 'error' | 'warning';
  message: string;
}

const saveToken = (token: string) => {
  return localStorage.setItem('@alunoensina_admin:token', token);
};

export const saveUser = (user: UserProps) => {
  localStorage.setItem('@alunoensina_admin:user', JSON.stringify(user));
};

export const getToken = () => localStorage.getItem('@alunoensina_admin:token');

export const getUser = () => {
  const user = localStorage.getItem('@alunoensina_admin:user');
  return user ? JSON.parse(user) : false;
};

export const isAuth = () => {
  return getUser();
};

export const verifyPassword = async (
  password: string
): Promise<MessageProps | undefined> => {
  try {
    const { email } = getUser();
    const response = await api.post('/auth/local', {
      identifier: email,
      password,
    });
    if (response.status === 200) {
      return { status: 'success', message: 'Confirmado!' };
    }
  } catch (err) {
    return { status: 'error', message: 'Senha inválida.' };
  }
};

export const authenticate = async (
  auth: LoginProps
): Promise<MessageProps | undefined> => {
  try {
    const response = await api.post('/auth/local', auth);
    if (response.status === 200) {
      const data = response.data;
      if (data.user.role.name !== 'Admin') {
        return {
          status: 'warning',
          message:
            'O usuário não tem permissão ou não faz parte do time de professores!',
        };
      }
      saveToken(data.jwt);
      saveUser({
        platform: data.user.platform,
        username: data.user.username,
        name: data.user.name,
        area: data.user.area,
        email: data.user.email,
        id: data.user.id,
        course: data.user.course,
        school: data.user.school,
        age: data.user.age,
        phone: data.user.phone,
        group: data.user.group,
        role: data.user.role,
      });
      return { status: 'success', message: 'Bem vindo!' };
    }
  } catch (err) {
    return { status: 'error', message: 'Email e/ou senha inválido.' };
  }
};

export const createUser = ({
  username,
  email,
  course,
  school,
  age,
  platform,
  area,
  password,
}: UserProps) => {
  return api.post('/users', {
    username: `${username} ${Date.now()}`,
    name: username,
    email,
    course,
    school,
    age,
    platform,
    area,
    password,
  });
};

export const confirmEmail = (email: string) => {
  return api.post('/auth/send-email-confirmation', {
    email,
  });
};

export const signOut = () => {
  localStorage.removeItem('@alunoensina_admin:token');
  localStorage.removeItem('@alunoensina_admin:user');
};

export const forgetPassword = async (email: string) => {
  try {
    const response = await api.post('/auth/forgot-password', {
      email,
    });
    showMessage({
      intent: 'success',
      message: 'E-mail para recuperar a senha foi enviado com sucesso!',
    });
    return response.data;
  } catch (err) {
    renderError(err);
  }
};

export const resetUserPassword = (
  code: string,
  password: string,
  passwordConfirmation: string
) => {
  return api.post('/auth/reset-password', {
    code,
    password,
    passwordConfirmation,
  });
};
