import { useState } from 'react';
import { renderNumber } from '../../utils/functions/Dashboard';
import SvgComponent from '../../components/Svg';
import * as Filter from './styles';
import * as Svg from '../../utils/constants/svgs';

type TextsProps = {
  menu: string;
  text: string;
  placeholder: string;
  buttonProp: string;
  buttonAscending: string;
  buttonDescending: string;
};

type FilterProps = {
  prop: any;
  isAscending: boolean;
};

type FilterComponentProps = {
  menu: MenuProps;
  search: string;
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
  setSearch: (search: string) => void;
  filters?: any[];
  modal?: React.ReactElement;
  children: React.ReactElement;
};

type MenuProps = 'lectures' | 'teachers' | 'students' | 'correction';

const filterText: TextsProps[] = [
  {
    menu: 'lectures',
    text: 'Ranking das aulas',
    placeholder: 'Busque por uma aula ou área',
    buttonProp: 'rating',
    buttonAscending: 'Maiores notas',
    buttonDescending: 'Menores notas',
  },
  {
    menu: 'teachers',
    text: 'Corretores na plataforma',
    placeholder: 'Busque por corretores',
    buttonProp: 'created_at',
    buttonAscending: 'Mais antigos',
    buttonDescending: 'Mais recentes',
  },
  {
    menu: 'students',
    text: 'Alunos na plataforma',
    placeholder: 'Busque por alunos',
    buttonProp: 'created_at',
    buttonAscending: 'Nome do aluno',
    buttonDescending: 'Nome do aluno',
  },
  {
    menu: 'correction',
    text: '',
    placeholder: 'Busque por titulo, nome ou tema de redação',
    buttonProp: 'created_at',
    buttonAscending: 'Mais antigos',
    buttonDescending: 'Mais recentes',
  },
];

export const FilterComponent = ({
  menu,
  search,
  filter,
  setSearch,
  setFilter,
  filters,
  modal,
  children,
}: FilterComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { prop, isAscending } = filter;
  const { text, placeholder, buttonProp, buttonAscending, buttonDescending } =
    filterText.find((f) => f.menu === menu) || filterText[0];

  const getIsAscending = (value: string): boolean => {
    return filter.prop === value && filter.isAscending === true;
  };

  return (
    <>
      {text ? <Filter.Subtitle>{text}</Filter.Subtitle> : null}
      <Filter.TableHeader>
        <Filter.SearchInput>
          <SvgComponent path={Svg.SEARCH} />
          <input
            type="text"
            placeholder={placeholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Filter.SearchInput>
        <Filter.TableHeaderFilter>
          {modal ? (
            <>
              <Filter.Button
                type="button"
                prop={isModalOpen}
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              >
                <SvgComponent path={Svg.BUTTON_FILTER} />
                Mover aluno
              </Filter.Button>
              {isModalOpen ? modal : null}
            </>
          ) : null}
          {filters ? (
            <Filter.Button
              type="button"
              prop={isOpen}
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {isOpen ? (
                <SvgComponent path={Svg.BUTTON_FILTER} />
              ) : renderNumber(filters) !== 0 ? (
                <Filter.Circle>{renderNumber(filters)}</Filter.Circle>
              ) : (
                <SvgComponent path={Svg.BUTTON_FILTER} />
              )}
              Filtros
            </Filter.Button>
          ) : null}
          <Filter.Rating
            prop={getIsAscending(buttonProp)}
            onClick={() =>
              filter.prop === buttonProp
                ? setFilter({ prop, isAscending: !isAscending })
                : setFilter({
                    prop: buttonProp,
                    isAscending: false,
                  })
            }
          >
            {getIsAscending(buttonProp) ? buttonAscending : buttonDescending}
            <SvgComponent path={Svg.FILTER} />
          </Filter.Rating>
        </Filter.TableHeaderFilter>
      </Filter.TableHeader>
      <Filter.Area prop={isOpen}>{children}</Filter.Area>
    </>
  );
};

export default FilterComponent;
