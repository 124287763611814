export const sortTable = (
  array: any[],
  filter: { prop: string; isAscending: boolean }
) => {
  return array.sort((a, b) => {
    if (a[filter.prop] === '' || a[filter.prop] === 0) {
      return 1;
    }
    if (b[filter.prop] === '' || b[filter.prop] === 0) {
      return -1;
    }

    if (a[filter.prop] < b[filter.prop]) {
      return filter.isAscending ? -1 : 1;
    }
    if (a[filter.prop] > b[filter.prop]) {
      return filter.isAscending ? 1 : -1;
    }
    return 0;
  });
};
