import styled from '@emotion/styled';

type ContainerProps = {
  isSelected: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  background-color: white;
  height: 22px;
  width: 22px;
  background-color: ${({ isSelected }) => (isSelected ? '#6FCAFF' : 'white')};
  border: 1px solid ${({ isSelected }) => (isSelected ? '#6FCAFF' : '#CDCED3')};
  border-radius: 3px;
  svg {
    border-radius: 3px;
    fill: ${({ isSelected }) => (isSelected ? 'white' : '#0E57B5')};
    height: 20px;
    width: 20px;
    background-color: #0e57b5;
  }
`;
