import { toast } from 'react-toastify';

type ShowMessageProps = {
  intent: 'success' | 'error' | 'warning';
  message: string;
};

export const showMessage = ({ intent, message }: ShowMessageProps) => {
  toast[intent](message, {
    position: 'top-center',
    autoClose: 3000,
  });
};