import Footer from '../Footer';
import Header from '../Header';
import { Main, Body } from './styles';
import { ToastContainer } from 'react-toastify';
import { ReactNode } from 'react';

export type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <Body>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <ToastContainer />
    </Body>
  );
};

export default Layout;
