import * as React from 'react';
import { Redirect, Route as ReactRoute } from 'react-router-dom';
import { RouteProps } from './routes';
import { getUser } from './../hooks/auth';

function AuthRoutes({ component: Component, ...rest }: RouteProps) {
  const auth = getUser();
  return (
    <ReactRoute
      {...rest}
      render={() => (!auth ? <Redirect to="/" /> : <Component />)}
    />
  );
}

export default AuthRoutes;
