import { Container, Title, Content } from './styles';
import { MAX_STUDENTS_GROUP_TEACHER } from '../../config/essay-group';

interface TeacherProps {
  name: string;
  id: number;
}
type ItemModalTeacherInfoProps = {
  teacher_name: string;
  totalEssay: number;
  isSelected?: boolean;
  id_teacher: TeacherProps;
  setSelectTeacher: (id: number) => void;
};

const ItemModalTeacherInfo = ({
  teacher_name,
  totalEssay,
  id_teacher,
  isSelected,
  setSelectTeacher,
}: ItemModalTeacherInfoProps) => {
  return (
    <Container
      isSelected={isSelected}
      onClick={() => {
        setSelectTeacher(id_teacher.id);
      }}
    >
      <Title isSelected={isSelected}>
        <h3>{teacher_name}</h3>
      </Title>
      <Content isSelected={isSelected}>
        {totalEssay} / {MAX_STUDENTS_GROUP_TEACHER} redações
      </Content>
    </Container>
  );
};

export default ItemModalTeacherInfo;
