export const handleGetBackground = (status: string) => {
  switch (status) {
    case 'Aberto':
    case 'Aprovado':
      return '#CEEFE0';

    case 'Fechado':
    case 'Corrigindo':
      return '#FCEDD4';
    default:
      return '#FFF7CC';
  }
};

export const handleGetTextColor = (status: string) => {
  switch (status) {
    case 'Aberto':
    case 'Aprovado':
      return '#0AAE65';

    case 'Fechado':
    case 'Corrigindo':
      return '#F1A728';

    default:
      return '#FFD800';
  }
};
