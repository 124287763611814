import Button from '../../components/Button';
import Input from '../../components/Input';
import { Main } from './styles';
import { showMessage } from '../../hooks/messages';
import {
  createEssayGroup,
  createTeacher,
  updateTeacherAddingGroupId,
} from '../../hooks/user';
import { useState, FormEvent, ChangeEvent } from 'react';
import { renderError } from '../../hooks/error';

const TeacherForm = () => {
  const emptyState = {
    name: '',
    specialty: '',
    email: '',
    password: '',
    id_teacher: 0,
  };
  const [form, setForm] = useState(emptyState);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange =
    (field: string | number | symbol) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setForm((previousForm) => ({ ...previousForm, [field]: value }));
    };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    setIsDisabled(true);
    try {
      const id_teacher = await createTeacher(form);

      if (!id_teacher) {
        showMessage({ intent: 'error', message: '' });
        return;
      }
      form.id_teacher = id_teacher;

      const idNewGroup = await createEssayGroup(form);

      await updateTeacherAddingGroupId({ id_teacher, id_group: idNewGroup });
      setForm(emptyState);
    } catch (err) {
      renderError(err);
      showMessage({
        intent: 'error',
        message: 'Não foi possível realizar o cadastro do professor',
      });
    }
    setIsDisabled(false);
  };

  return (
    <Main>
      <h2>Novo cadastro</h2>
      <p>Insira os dados para cadastrar um professor no portal</p>
      <form onSubmit={submit}>
        <Input
          placeholder="Digite aqui seu nome"
          labelText="Name"
          name="name"
          type="text"
          value={form.name}
          change={handleChange}
          required
        />
        <Input
          placeholder="Digite aqui seu email"
          labelText="Email"
          name="email"
          type="email"
          value={form.email}
          change={handleChange}
          required
        />
        <Input
          placeholder="Digite aqui a especialidade"
          labelText="Especialidade"
          name="specialty"
          type="text"
          value={form.specialty}
          change={handleChange}
          required
        />
        <Input
          passwordVisible
          placeholder="Digite aqui sua senha"
          labelText="Senha"
          name="password"
          type="password"
          value={form.password}
          change={handleChange}
          required
        />
        <Button
          type="submit"
          text={isDisabled ? 'Cadastrando' : 'Cadastrar'}
          disabled={isDisabled}
        />
      </form>
    </Main>
  );
};

export default TeacherForm;
