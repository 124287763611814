import { Spinner } from './styles';

type SpinnerProps = {
  className?: string;
};

const Loading = ({ className }: SpinnerProps) => (
  <Spinner className={className} />
);

export default Loading;
