import { ContainerFooter, Content, Text, Icons } from './styles';
import Logo from '../../images/imgloginteacher.svg';
import {
  MAIN_LINKS_FOOTER,
  ICONS_SOCIAL_MEDIA,
} from '../../utils/constants/footer';

const Footer = () => {
  const handleIcons = (index: number, path: string, link: string) => {
    return (
      <a href={link} key={index}>
        <svg>
          <path d={path} />
        </svg>
      </a>
    );
  };

  const handleNavigate = (index: number, text: string, link: string) => {
    return (
      <a key={index} href={link}>
        {text}
      </a>
    );
  };

  return (
    <ContainerFooter>
      <Content>
        <Icons>
          <img src={Logo} alt="logo" />
          <div>
            {ICONS_SOCIAL_MEDIA.map((icon, index) =>
              handleIcons(index, icon.path, icon.link)
            )}
          </div>
        </Icons>
        <Text>
          <div>
            <p>
              Copyright © 2020 Aluno Ensina |<br />A maior plataforma de ensino
              comunitário do Brasil
            </p>
          </div>
          <div>
            {MAIN_LINKS_FOOTER.map((menu, index) =>
              handleNavigate(index, menu.text, menu.link)
            )}
          </div>
        </Text>
      </Content>
    </ContainerFooter>
  );
};

export default Footer;
