import Layout from '../../containers/Layout';
import OptionItem from '../../components/OptionItem';
import { Content, Option } from './styles';
import NewThemeContainer from '../../containers/NewTheme';
import { Link } from 'react-router-dom';

function NewTheme() {
  return (
    <Layout>
      <Content>
        <Option>
          <OptionItem>
            <Link to="/essays">Redações</Link>
          </OptionItem>
          <OptionItem selected>
            <Link to="/new-theme">Novo tema</Link>
          </OptionItem>
        </Option>
        <NewThemeContainer />
      </Content>
    </Layout>
  );
}

export default NewTheme;
