import styled from '@emotion/styled';

type ButtonModalProps = {
  backgroundColor: string;
  color: string;
  center?: boolean;
};

type TagProps = {
  isSelected: boolean;
};

type ModalEssaySelectedProps = {
  isHover: boolean;
};

type BoxModalProps = {
  isOpenModel: boolean;
};

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;

  font-family: Mulish;

  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;

  margin: 0 auto;
  margin-bottom: 230px;
`;

export const HeaderBack = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

export const BreadCrumbs = styled.div`
  margin-left: 70px;
  color: #9aa0ba;
  font-size: 1.2rem;

  span {
    color: #6fcaff;
  }
`;

export const Title = styled.div`
  margin-top: 20px;

  h1 {
    font-weight: bold;
  }

  h3 {
    margin-top: 20px;
    color: #9aa0ba;
  }
`;

export const TagGroups = styled.div`
  display: flex;

  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const TagLeft = styled.button<TagProps>`
  width: 200px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  color: ${({ isSelected }) => (isSelected ? 'white' : '#6FCAFF')};
  background-color: ${({ isSelected }) => (isSelected ? '#6FCAFF' : '#E2F4FF')};
  border-top-left-radius: 8px;
  border: none;
`;

export const TagRight = styled.button<TagProps>`
  width: 200px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  color: ${({ isSelected }) => (isSelected ? 'white' : '#6FCAFF')};
  background-color: ${({ isSelected }) => (isSelected ? '#6FCAFF' : '#E2F4FF')};
  border-top-right-radius: 8px;
  border: none;
`;

export const GroupHeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 50px;
  margin-bottom: 30px;
`;
export const GroupButtons = styled.div`
  display: flex;
  position: relative;
`;

export const GroupButtonsModal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonModal = styled.button<ButtonModalProps>`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 10px;
  width: 220px;
  height: 50px;
  font-size: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  color: ${({ color }) => color};

  margin-right: 15px;
  @media (max-width: 500px) {
    margin-right: 0px;
  }

  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor}cc;
  }
`;

export const ButtonInfoModal = styled.button<ButtonModalProps>`
  width: 220px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 10px;
  font-size: 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  color: ${({ color }) => color};

  &:hover {
    background-color: ${({ backgroundColor }) => backgroundColor}cc;
  }
`;

export const Table = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const BoxModal = styled.div<BoxModalProps>`
  z-index: 11;
  width: 100%;
  max-height: 550px;
  max-width: 400px;
  display: ${({ isOpenModel }) => (isOpenModel ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  padding: 10px;

  top: 70px;
  left: 140px;

  @media (max-width: 500px) {
    top: 120px;
    left: 0px;
  }

  box-shadow: 2px 2px 2px #ccc;

  h3 {
    font-weight: bold;
    padding: 5px 0;
  }
`;

export const EssaySelectedModal = styled.div`
  color: #9aa0ba;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export const EssaysSelectedBodyModal = styled.div<ModalEssaySelectedProps>`
  width: 200px;
  height: 400px;

  display: ${({ isHover }) => (isHover ? 'flex' : 'none')};
  flex-direction: column;
  overflow-y: scroll;
  right: 0;

  position: absolute;
  background: #7a7a7a;
  border-radius: 5px;
  padding: 5px;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    background: #f4f4f4;
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dad7d7;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  scrollbar-color: #dad7d7;
  scrollbar-width: thin;

  @media (max-width: 500px) {
    margin-top: 50px;
  }

  p {
    color: white;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const GroupItems = styled.div`
  width: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    border-radius: 10px;
    background: #f4f4f4;
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #dad7d7;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  scrollbar-color: #dad7d7;
  scrollbar-width: thin;

  @media (max-width: 500px) {
    margin-top: 50px;
  }
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 2rem;
`;
