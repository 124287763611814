import Layout from '../../containers/Layout';
import MoveEssayByTeacher from '../../containers/MoveEssayByTeacher/index';

function MoveTeacherEssay() {
  return (
    <Layout>
        <MoveEssayByTeacher/>
    </Layout>
  );
}

export default MoveTeacherEssay;
