import styled from '@emotion/styled';

export const Context = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    height: 20px;
    width: 100px;
  }
`;

export const Content = styled.div`
  margin-top: 20px;
  height: 20px;
  left: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  color: #6fcaff;
  position: absolute;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    margin-bottom: 25px;
  }
  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
  p {
    margin: 3% 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 5rem;
  }

  form {
    width: 100%;
    max-width: 450px;
  }

  a:not(::first-of-type) {
    font-style: normal;
    font-weight: bold;
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center;
    color: #db3535;
  }
`;

export const ComeBack = styled.div`
  position: absolute;
  top: 0px;
  left: 20px;
  font-size: 1rem;
`;

export const Back = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 20px;
  svg {
    fill: #6fcaff;
  }
  width: 1.1rem;
`;

export const ForgetPassword = styled.div`
  margin-top: 30px;
  width: 400px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  text-decoration-line: underline;
  color: #9aa0ba;
`;

export const Right = styled.div`
  position: relative;
  width: 28.3%;
  img {
    width: 30.1rem;
    height: 100vh;
    position: absolute;
    opacity: 0.7;
    z-index: -1;
    object-fit: cover;
  }
  h2 {
    margin: 0;
    padding: 0;
    font-family: Metropolis;
    font-size: 1.2rem;
    text-align: center;
    color: #fefeff;
    text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const Cancel = styled.a`
  margin-top: 2rem;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 18px;
  text-align: center;
  color: #db3535;
`;
