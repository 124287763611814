import CheckBoxItemTable from '../CheckBoxItemTable/index';
import { TR, TD, Title, Text, Status, Button } from './styles';
import { BLOCK_ICON, AUTHORIZE_ICON } from '../../utils/constants/svgs';
import {
  handleGetBackground,
  handleGetTextColor,
} from '../../utils/functions/TableItem/table_item_student';

interface TableItemThemeProps {
  theme: string;
  created_at: string;
  close_at: string;
  essayNumber: number;
  status: string;
}

const TableItemTheme = ({
  theme,
  created_at,
  close_at,
  essayNumber,
  status,
}: TableItemThemeProps) => {
  return (
    <TR>
      <TD>
        <CheckBoxItemTable isSelected={false} />
      </TD>
      <TD>
        <Title>Tema</Title> <Text>{theme}</Text>
      </TD>
      <TD>
        <Title>Criada em</Title>
        <Text>{created_at}</Text>
      </TD>
      <TD>
        <Title>Fecha em</Title>
        <Text>{close_at}</Text>
      </TD>
      <TD>
        <Title>Redações Submetidas</Title>
        <Text>-</Text>
      </TD>
      <TD>
        <Text>
          <Status
            background={handleGetBackground(status)}
            color={handleGetTextColor(status)}
          >
            <div></div>
            <div>{status}</div>
          </Status>
        </Text>
      </TD>
      <TD>
        <Text>
          <Button onClick={() => {}}>
            <svg>
              {status === 'Aberto' ? (
                <path d={AUTHORIZE_ICON} />
              ) : (
                <path d={BLOCK_ICON} />
              )}
            </svg>
          </Button>
        </Text>
      </TD>
    </TR>
  );
};

export default TableItemTheme;
