import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type IBreadCrumb = {
  active?: boolean;
  redirect?: boolean;
};

export const BreadCrumbs = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
`;

export const BreadCrumb = styled(Link)<IBreadCrumb>`
  color: ${({ active }) => (active ? '#0E57B5' : '#9AA0BA')};
  cursor: ${({ redirect }) => (redirect ? 'pointer' : 'initial')};
  font-weight: bold;
  text-decoration: none;
  :not(:first-of-type) {
    ::before {
      content: '/';
      margin: 0px 2px 0px 5px;
    }
  }
`;
