import styled from '@emotion/styled';

export const ContainerFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f9;
  opacity: 1;
  min-height: 250px;
  position: relative;
`;

export const Content = styled.div`
  display: grid;
  max-width: 1200px;
  width: 100%;
  grid-gap: 1rem;
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  @media (max-width: 1300px) {
    margin: 2rem;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column-reverse;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const Text = styled.div`
  p {
    margin: 0px;
    text-align: left;
    font-family: Mulish;
    font-size: 1rem;
    letter-spacing: 0.48px;
    font-weight: bold;
    color: #828282;
    opacity: 1;
    font-style: italic;
  }
  a {
    width: max-content;
    font-weight: bold;
    font-family: Mulish;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    margin-left: 1rem;
    color: #828282;

    &:hover {
      color: #38b6ff;
    }
  }
  div {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    div {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    a {
      margin: 0px;
    }
    a:not(:first-of-type) {
      margin-top: 2rem;
    }
    p {
      text-align: center;
    }
  }
`;

export const Icons = styled.div`
  svg {
    margin-left: 2rem;
    padding: 0px;
    height: 30px;
    width: 30px;
    fill: #4f4f4f;
    &:hover {
      fill: #38b6ff;
    }
  }

  @media (max-width: 850px) {
    img {
      display: none;
    }
  }

  @media (max-width: 850px) {
    div {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
    svg {
      margin: 0px;
    }
  }
`;
