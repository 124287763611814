import { useEffect, useState } from 'react';
import LoginImg from '../../images/login.svg';
import LogoImg from '../../images/logoLogin.png';
import { authenticate, isAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { showMessage } from '../../hooks/messages';
import { useForm } from 'react-hook-form';
import { Input, InputPassord } from '../../components/InputHooks';
import { Background, Image, Img, Icon, Button, Svg, Content } from './styles';

type FormValues = {
  identifier: string;
  password: string;
};

const Login = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: { identifier: '', password: '' },
  });
  useEffect(() => {
    if (isAuth()) {
      history.push('/correctors');
    }
  }, [history]);

  const submit = async (data: FormValues) => {
    setIsLoading(true);
    const response = await authenticate(data);
    response &&
      showMessage({ intent: response.status, message: response.message });
    setIsLoading(false);
    if (response?.status === 'success') {
      setTimeout(() => history.push('/correctors'), 2000);
    }
    return;
  };

  return (
    <Background>
      <Image>
        <Img src={LoginImg} />
      </Image>
      <Content>
        <Icon src={LogoImg} />
        <h1>Bem vindo!</h1>
        <form onSubmit={handleSubmit(submit)}>
          <Input
            placeholder="Informe seu email de login"
            label="Email"
            type="email"
            {...register('identifier', {
              required: 'Email é um campo obrigatório',
            })}
            error={errors.identifier?.message}
          />
          <InputPassord
            placeholder="Qual sua senha?"
            label="Senha"
            {...register('password', {
              required: 'Senha é um campo obrigatório',
            })}
            error={errors.password?.message}
          />
          <Button type="submit">{isLoading ? <Loading /> : 'Entrar'}</Button>
        </form>
        <a href="/forgot-password">Esqueci minha senha</a>
        <p>
          Ainda não faz parte da comunidade Aluno Ensina?
          <a href="/signup"> Comece por aqui</a>
        </p>
      </Content>
    </Background>
  );
};

const Loading = () => (
  <Svg
    version="1.1"
    id="L9"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    enable-background="new 0 0 0 0"
    xmlSpace="preserve"
  >
    <path
      fill="#fff"
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </Svg>
);

export default Login;
