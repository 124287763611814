import styled from '@emotion/styled';
import LoadingComponent from '../../components/Loading';
import Svg from '../../components/Svg';

type IToggle = {
  isAscending: boolean;
};

type IComplaint = {
  isComplaint: boolean;
};

export const Loading = styled(LoadingComponent)`
  padding: 30vh 0px;
`;

export const Background = styled.div`
  width: 100%;
  min-height: 60vh;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 0px auto;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 2rem;
  margin: 0.5rem 0px;
`;

export const Subtitle = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1.5rem 0px 0.8rem 0px;
`;

export const Link = styled.a`
  text-decoration: none;
  width: max-content;
  font-size: 1rem;
  color: #0e57b5;
  font-weight: bold;
  cursor: pointer;
`;

export const ArrowLink = styled.h3`
  display: flex;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: white;
  border: 0.05px solid #f2f2f2;
  box-shadow: 0px 4px 12px rgba(5, 10, 36, 0.05);
  border-radius: 5px;
`;

export const InfoContent = styled.div`
  display: flex;
`;

export const InfoContentBottom = styled.div`
  margin-top: 1rem;
`;

export const Info = styled.div`
  flex-direction: column;
  margin-right: 2rem;
`;

export const InfoLabel = styled.label`
  font-size: 1.1rem;
  color: #a8aabc;
`;

export const InfoText = styled.h2`
  margin: 0px;
  font-weight: normal;
  font-size: 1.2rem;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin: 1rem 0px;
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(5, 10, 36, 0.05);
  border: 0.5px solid #f2f2f2;
  background-color: #fff;
  color: #444655;
`;

export const CardContentFirst = styled.div`
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 0.8rem;
`;

export const CardText = styled.h2`
  margin: 0px;
  font-size: 1.8rem;
`;

export const CardSpan = styled.span`
  font-size: 1.2rem;
`;

export const SvgIcon = styled(Svg)`
  width: 17px;
  height: 16px;
  margin-right: 0.5rem;
  fill: #a8aabc;
`;

export const SvgDocument = styled(Svg)`
  width: 25px;
  height: 30px;
  margin-right: 0.5rem;
  fill: #a8aabc;
`;

export const AllMaterials = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CardMaterial = styled.a`
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: max-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #050a24;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: 2px solid #f2f2f2;
  transition: 100ms ease-in-out;
  :hover {
    transform: scale(1.1);
  }
`;

export const MaterialContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MaterialText = styled.h2`
  font-size: 1.1rem;
  margin: 0px;
`;

export const MaterialSpan = styled.span`
  color: #38b6ff;
  font-size: 0.9rem;
`;

export const Button = styled.button`
  font-size: 1.1rem;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: max-content;
  border-radius: 5px;
  background-color: #fff;
  border: 2px solid #f2f2f2;
  color: #444655;
`;

export const CommentHeader = styled.div<IToggle>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 12px;
    height: 8px;
    margin-left: 0.5rem;
    transform: ${({ isAscending }) =>
      isAscending ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
  }
`;

export const Comment = styled.div<IComplaint>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  box-shadow: 0px 16px 20px rgba(5, 10, 36, 0.051);
  border: 0.5px solid
    ${({ isComplaint }) => (isComplaint ? '#f81212' : '#e6e7e9')};

  h2 {
    color: #050a24;
    margin: 0px;
  }

  label {
    color: #a8aabc;
    margin-bottom: 1rem;
  }
`;

export const CommentContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1rem;
  color: #9298b5;
  div {
    display: flex;
    align-items: center;
  }
  svg {
    height: 30px;
    width: 30px;
  }
`;

export const Complaint = styled.div`
  color: #fff;
  background-color: #f81212;
  padding: 0.5rem;
  border-radius: 5px;
`;

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SvgArrow = styled(Svg)`
  fill: #0e57b5;
  width: 12px;
  height: 8px;
  margin-left: 0.5rem;
  transform: rotateZ(90deg);
`;

export const SvgToggle = styled(Svg)`
  fill: #a8aabc;
`;

export const SvgLike = styled(Svg)`
  transform: translateY(-2px) scale(0.9);
  fill: #a8aabc;
  margin-right: 2px;
`;

export const SvgRemove = styled(Svg)<IComplaint>`
  margin-left: 1rem;
  height: 26px;
  width: 25px;
  cursor: pointer;
  fill: ${({ isComplaint }) => (isComplaint ? '#f81212' : '#a8aabc')};
`;

export const SvgStar = styled(Svg)`
  margin-left: 1rem;
  height: 18px;
  width: 18px;
  transform: scale(1.3);
  fill: #ffd600;
`;
