import { useState, useEffect } from 'react';
import { Dashboard, CardsProps } from '../../components/Dashboard';
import { PAGINATION_LECTURES_LIMIT } from '../../config/lecture/table';
import { showMessage } from '../../hooks/messages';
import { sortTable } from '../../utils/functions/Table/table-functions';
import api from '../../services/api';
import Layout from '../../containers/Layout';
import Loading from '../../components/Loading';
import TablePaginationHeader from '../../components/TablePaginationHeader';
import FilterComponent from '../../components/FilterComponent';
import { UserProps } from '../../domain/user';
import { formatDate } from '../../utils/functions/Date';
import Checkbox from '../../components/DashboardCheckbox';
import { EssayGroupProps } from '../../domain/essayGroups';
import { onlyNumbers } from '../../utils/functions/Dashboard';
import { EssayProps } from '../../domain/essay';
import * as S from './styles';
import * as Svg from '../../utils/constants/svgs';
import * as Input from '../../components/SelectDashboard/styles';

type Props = 'name' | 'created_at' | 'teacher' | 'essays' | 'group';

type TableHeaderObj = {
  name: string;
  sort: string;
};

type FilterProps = {
  prop: Props;
  isAscending: boolean;
};

type StudentToShow = {
  id: number;
  name: string;
  created_at: string;
  teacher: string;
  essays: number;
  group: number;
  status: string;
};

type StudentProp = {
  student: StudentToShow;
  selectedStudents: StudentToShow[];
  setSelectedStudents: React.Dispatch<React.SetStateAction<StudentToShow[]>>;
  changeStatus: (status: string, id: number) => void;
};

type TableHeadProps = {
  filter: FilterProps;
  setFilter: (filter: FilterProps) => void;
  getIsAscending: (prop: string) => boolean;
  students: StudentToShow[];
  selectedStudents: StudentToShow[];
  setSelectedStudents: React.Dispatch<React.SetStateAction<StudentToShow[]>>;
};

const Student = () => {
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [startOffset, setStartOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [searchModal, setSearchModal] = useState('');
  const [totalPage, setTotalPage] = useState<number[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [students, setStudents] = useState<UserProps[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<StudentToShow[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<EssayGroupProps | null>(
    null
  );
  const [groups, setGroups] = useState<EssayGroupProps[]>([]);
  const [filterEssay, setFilterEssay] = useState<number | null>(null);
  const [filterGroup, setFilterGroup] = useState<number | null>(null);
  const [pending, setPending] = useState('-');
  const [active, setActive] = useState('-');
  const [inactive, setInactive] = useState('-');
  const [filter, setFilter] = useState<FilterProps>({
    prop: 'name',
    isAscending: false,
  });

  useEffect(() => {
    Promise.all([
      api.get<UserProps[]>(`/users?role=3`).then(({ data }) => {
        setStudents(data);
        setIsTableLoading(false);

        const pending = data.filter(({ request_essay }) => request_essay);
        const active = data.filter(({ essay_authorized }) => essay_authorized);
        const inactive = data.filter(
          (user: UserProps) => !user.essay_authorized && !user.request_essay
        );
        setPending(pending.length.toString());
        setActive(active.length.toString());
        setInactive(inactive.length.toString());
        const totalPages = Math.round(data.length / PAGINATION_LECTURES_LIMIT);
        const arrayPages = Array.from({ length: totalPages }, (_, i) => i + 1);
        setTotalPage(arrayPages);
        setTotal(data.length);
      }),
      api.get(`/essay-groups`).then(({ data }) => {
        setGroups(data);
      }),
    ]).catch(() => {
      showMessage({
        intent: 'error',
        message: 'Erro ao buscar dados',
      });
    });
  }, []);

  const formatStudents = () => {
    const studentToShow = students.map((s) => {
      const {
        id,
        name,
        created_at,
        essays,
        request_essay,
        essay_authorized,
        group,
      } = s;

      const groupInfo = groups.find((g) => g.id === group?.id);
      const getStatus = () => {
        if (!request_essay) {
          return essay_authorized ? 'Aprovado' : 'Inativo';
        }
        return 'Pendente';
      };

      return {
        id,
        name,
        created_at,
        teacher: groupInfo?.id_teacher.name ?? '',
        essays: essays.length,
        group: groupInfo?.id ?? 0,
        status: getStatus(),
      };
    });

    return studentToShow.filter((s) => {
      if (filterEssay || filterGroup) {
        if (filterEssay && filterGroup) {
          return s.essays === filterEssay && s.group === filterGroup;
        }
        return s.essays === filterEssay || s.group === filterGroup;
      }
      return true;
    });
  };

  const changeStatus = (status: string, id: number) => {
    const isStatusPendingOrInactive =
      status === 'Pendente' || status === 'Inativo';
    const studentStatus = {
      essay_authorized: isStatusPendingOrInactive,
      request_essay: !isStatusPendingOrInactive,
    };

    api
      .put(`/users/${id}`, studentStatus)
      .then(({ data }) => {
        setStudents((prevState) => {
          const allStudents = prevState.filter((s) => s.id !== data.id);
          return [...allStudents, data];
        });
        showMessage({
          intent: 'success',
          message: 'Aluno modificado com sucesso!',
        });
      })
      .catch(() => {
        showMessage({
          intent: 'error',
          message: 'Erro ao atualizar aluno.',
        });
      });
  };

  const studentsFiltered = formatStudents().filter(
    (elem) =>
      elem.name.toLowerCase().includes(search.toLowerCase()) ||
      elem.teacher.toLowerCase().includes(search.toLowerCase())
  );

  const groupsFiltered = groups.filter((elem) =>
    elem.id_teacher.name.toLowerCase().includes(searchModal.toLowerCase())
  );

  const getIsAscending = (value: string): boolean => {
    return filter.prop === value && filter.isAscending;
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    setStartOffset((page - 1) * PAGINATION_LECTURES_LIMIT);
  };

  const isStudentsFit = (id: number) => {
    const group = groups.find((group) => group.id === id);
    const selectedStudentsIds = selectedStudents.map((student) => student.id);
    const usersAlreadyAdded =
      group?.users.filter((user) => selectedStudentsIds.includes(user.id))
        .length || 0;
    if (group) {
      return (
        selectedStudents.length - usersAlreadyAdded >
        group.student_limit - group.users.length
      );
    }
    return false;
  };

  const moveStudents = () => {
    if (selectedGroup) {
      if (selectedStudents.length === 0) {
        return showMessage({
          intent: 'error',
          message: 'Nenhum aluno selecionado',
        });
      }
      updateGroup(selectedGroup, selectedStudents);
      selectedStudents.forEach((student) => updateUser(student, selectedGroup));
      const selectedStudentsIds = selectedStudents.map((student) => student.id);
      let allEssays: EssayProps[] = [];
      students
        .filter((student) => selectedStudentsIds.includes(student.id))
        .forEach((student) => {
          allEssays = [...allEssays, ...student.essays];
        });
      updateGroupEssays(allEssays, selectedGroup);
      setSelectedGroup(null);
      setSelectedStudents([]);
      return showMessage({
        intent: 'success',
        message: 'Aluno movido com sucesso!',
      });
    }
    return showMessage({
      intent: 'error',
      message: 'Nenhum grupo selecionado',
    });
  };

  const updateGroup = (group: EssayGroupProps, students: StudentToShow[]) => {
    const oldUsers = group.users.filter(
      (user) => !students.map((student) => student.id).includes(user.id)
    );
    const isAlreadyInGroup = students.filter(
      (student) => +student.group !== group.id && +student.group !== 0
    );
    if (!!isAlreadyInGroup.length) {
      groups.forEach((g) => {
        const isGroupNeedToUpdate = !!isAlreadyInGroup.filter(
          (student) => +student.group === g.id
        ).length;
        const usersIds = isAlreadyInGroup.map((student) => student.id);
        const usersUpdated = g.users.filter(
          (allUsers) => !usersIds.includes(allUsers.id)
        );
        const essaysUpdated = g.essays.filter(
          (allEssays) => !usersIds.includes(+allEssays.idUser)
        );
        if (isGroupNeedToUpdate) {
          api
            .put(`/essay-groups/${g.id}`, {
              users: usersUpdated,
              essays: essaysUpdated,
            })
            .then(({ data }) => {
              setGroups((prevState) => {
                const otherGroups = prevState.filter(
                  (group) => group.id !== data.id
                );
                return [...otherGroups, data];
              });
            })
            .catch(() =>
              showMessage({
                intent: 'error',
                message:
                  'Ocorreu um erro durante a atualização do grupo antigo',
              })
            );
        }
      });
    }
    api
      .put(`/essay-groups/${group.id}`, {
        users: [...oldUsers, ...students],
      })
      .then(({ data }) => {
        setStudents((prevState) => {
          const studentIds = data.users.map((student: UserProps) => student.id);
          const studentsUpdated = prevState.map((student) =>
            studentIds.includes(student.id)
              ? { ...student, group: data }
              : student
          );
          return studentsUpdated;
        });
        setGroups((prevState) => {
          const otherGroups = prevState.filter((group) => group.id !== data.id);
          return [...otherGroups, data];
        });
      })
      .catch(() =>
        showMessage({
          intent: 'error',
          message: 'Ocorreu um erro durante a atualização do grupo',
        })
      );
  };

  const updateGroupEssays = async (essays: any[], group: EssayGroupProps) => {
    await api.put(`/essay-groups/${group.id}`, {
      essays: [...essays, ...group.essays],
    });
  };

  const updateUser = async (student: StudentToShow, group: EssayGroupProps) => {
    await api
      .put(`/users/${student.id}`, { group: group.id })
      .then(({ data }) => {
        data.essays.forEach((essay: any) => {
          api
            .put(`/essays/${essay.id}`, {
              essay_group: group.id,
              id_teacher: group.id_teacher.id,
            })
            .catch(() =>
              showMessage({
                intent: 'error',
                message: 'Ocorreu um erro durante a atualização de uma redação',
              })
            );
        });
      })
      .catch(() =>
        showMessage({
          intent: 'error',
          message: 'Ocorreu um erro durante a atualização de um aluno',
        })
      );
  };

  const cards: CardsProps[] = [
    {
      label: 'Inativos',
      text: inactive,
    },
    {
      label: 'Pendentes',
      text: pending,
    },
    {
      label: 'Ativos',
      text: active,
    },
  ];

  return (
    <Layout>
      <S.Container>
        <Dashboard
          title="Alunos"
          subtitle="Dashboard dos alunos"
          cards={cards}
        />
        {isTableLoading ? (
          <S.Loading>
            <Loading />
          </S.Loading>
        ) : (
          <>
            <FilterComponent
              menu="students"
              search={search}
              setSearch={setSearch}
              filter={filter}
              setFilter={setFilter}
              filters={[filterEssay, filterGroup]}
              modal={
                <S.Modal>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Digite o nome de um professor"
                      value={searchModal}
                      onChange={(e) => setSearchModal(e.target.value)}
                    />
                    <S.SvgSearch path={Svg.SEARCH} />
                  </Input.Item>
                  <S.Content>
                    {groupsFiltered.map((g) => {
                      const isSelected = () => {
                        const selected = selectedGroup?.id === g.id;
                        if (selected && isStudentsFit(g.id)) {
                          setSelectedGroup(null);
                        }
                        return selected;
                      };
                      return (
                        <S.Group
                          key={g.id}
                          onClick={() =>
                            !isStudentsFit(g.id) && setSelectedGroup(g)
                          }
                          isDisabled={isStudentsFit(g.id)}
                          isSelected={isSelected()}
                        >
                          <S.Teacher>
                            <S.GroupName>{g.name}</S.GroupName>
                            <S.Span>
                              {g.users.length}/{g.student_limit} alunos
                            </S.Span>
                          </S.Teacher>
                          <S.TeacherName>
                            Profª {g.id_teacher.name}
                          </S.TeacherName>
                        </S.Group>
                      );
                    })}
                  </S.Content>
                  <S.Confirm onClick={() => moveStudents()}>
                    Confirmar
                  </S.Confirm>
                </S.Modal>
              }
            >
              <S.Filter>
                <Input.Container>
                  <h2>Redações</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Redações enviadas"
                      value={filterEssay?.toString() ?? ''}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterEssay(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
                <Input.Container>
                  <h2>Grupo</h2>
                  <Input.Item>
                    <input
                      type="text"
                      placeholder="Grupo"
                      value={filterGroup?.toString()}
                      onChange={(e) => {
                        const number = onlyNumbers(e.target.value);
                        setFilterGroup(!!number ? parseInt(number) : null);
                      }}
                    />
                  </Input.Item>
                </Input.Container>
              </S.Filter>
            </FilterComponent>
            <S.Table>
              <TableHeader
                filter={filter}
                setFilter={setFilter}
                getIsAscending={getIsAscending}
                students={studentsFiltered}
                selectedStudents={selectedStudents}
                setSelectedStudents={setSelectedStudents}
              />
              <S.TableBody>
                {sortTable(studentsFiltered, filter)
                  .slice(startOffset, startOffset + PAGINATION_LECTURES_LIMIT)
                  .map((student: any) => (
                    <TableItem
                      key={student.name}
                      student={student}
                      selectedStudents={selectedStudents}
                      setSelectedStudents={setSelectedStudents}
                      changeStatus={changeStatus}
                    />
                  ))}
              </S.TableBody>
            </S.Table>
            <TablePaginationHeader
              pages={totalPage}
              limit={PAGINATION_LECTURES_LIMIT}
              totalItems={total}
              currentPage={page}
              handleClick={handleChangePage}
              label="usuários"
            />
          </>
        )}
      </S.Container>
    </Layout>
  );
};

const TableHeader = ({
  filter,
  setFilter,
  getIsAscending,
  students,
  selectedStudents,
  setSelectedStudents,
}: TableHeadProps) => {
  const thead: TableHeaderObj[] = [
    { name: 'Aluno', sort: 'name' },
    { name: 'Cadastro', sort: 'created_at' },
    { name: 'Professor', sort: 'teacher' },
    { name: 'Redações', sort: 'essays' },
    { name: 'Grupo', sort: 'group' },
    { name: 'Status da solicitação', sort: 'status' },
  ];

  const isSelected = selectedStudents.length === students.length;

  const setSelected = () => {
    setSelectedStudents(isSelected ? [] : [...students]);
  };

  return (
    <S.TableHead>
      <S.TR>
        <S.TH>
          <Checkbox isChecked={isSelected} change={() => setSelected()} />
        </S.TH>
        {thead.map((head, index) => {
          const { name, sort } = head;
          const { prop, isAscending } = filter;
          return (
            <S.TH key={index}>
              <div
                onClick={() => {
                  if (sort === prop) {
                    setFilter({ prop, isAscending: !isAscending });
                    return;
                  }
                  setFilter({ prop: sort as Props, isAscending: false });
                }}
              >
                {name}
                <S.SvgArrow path={Svg.ARROW_DOWN} prop={getIsAscending(sort)} />
              </div>
            </S.TH>
          );
        })}
      </S.TR>
    </S.TableHead>
  );
};

const TableItem = ({
  student,
  selectedStudents,
  setSelectedStudents,
  changeStatus,
}: StudentProp) => {
  const isSelected = selectedStudents.some((s) => s.id === student.id);
  const setSelected = (student: StudentToShow) => {
    setSelectedStudents((prevState: StudentToShow[]) =>
      isSelected
        ? prevState.filter((s) => s.id !== student.id)
        : [...prevState, student]
    );
  };

  return (
    <S.TR>
      <S.TD>
        <Checkbox isChecked={isSelected} change={() => setSelected(student)} />
      </S.TD>
      <S.TD>{student.name.toString()}</S.TD>
      <S.TD>{formatDate(student.created_at)}</S.TD>
      <S.TD>{student.teacher.length ? student.teacher : '-'}</S.TD>
      <S.TD>{student.essays ?? '-'}</S.TD>
      <S.TD>{student.group !== 0 ? student.group : '-'}</S.TD>
      <S.TD>{student.status}</S.TD>
      <S.TD center onClick={() => changeStatus(student.status, student.id)}>
        <S.SvgApproved
          path={student.status === 'Aprovado' ? Svg.BLOCK_ICON : Svg.APPROVED}
        />
      </S.TD>
    </S.TR>
  );
};

export default Student;
