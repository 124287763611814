import { signOut } from './auth';
import { showMessage } from './messages';

export const renderError = (e: any) => {
  const statusCode = e.response?.data?.statusCode;
  if (statusCode === 401 || statusCode === 403 || showMessage === undefined) {
    signOut();
    showMessage({
      intent: 'error',
      message: 'Sessão expirada, faça o login novamente',
    });
    window.location.reload();
  }

  if (typeof e.response?.data.message === 'string') {
    return showMessage({ intent: 'error', message: e.response?.data.message });
  }
};
