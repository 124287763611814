import * as Dash from './styles';
import Card from '../Card';

export type CardsProps = {
  label: string;
  text: string;
  svg?: string;
  subinfo?: string;
};

type DashboardProps = {
  title: string;
  subtitle: string;
  cards: CardsProps[];
};

export const Dashboard = ({ title, subtitle, cards }: DashboardProps) => {
  return (
    <>
      <Dash.Title>{title}</Dash.Title>
      <Dash.Subtitle>{subtitle}</Dash.Subtitle>
      <Dash.Cards>
        {cards.map((card, index) => {
          return <Card key={index + card.text} {...card} />;
        })}
      </Dash.Cards>
    </>
  );
};
