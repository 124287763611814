import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :after {
    content: '';
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #0e57b5;
    width: 60px;
    height: 60px;
    animation: ${Spin} 1.5s linear infinite;
  }
`;
