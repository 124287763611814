import styled from '@emotion/styled';

interface ColorProps {
  isGreyScale?: boolean;
}

interface VisibleProps {
  visible: boolean;
}

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 0px;
  padding: 0px;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0px;
    margin-bottom: 0.3rem;
    color: red;
    text-align: initial;
  }
`;

export const Label = styled.label<ColorProps>`
  position: absolute;
  margin-top: 0.5rem;
  padding-left: 0.8rem;
  text-align: left;
  font-family: Mulish;
  font-size: 1rem;
  letter-spacing: 0.7px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
  z-index: 2;
  color: ${({ isGreyScale }) => (isGreyScale ? '#9298b5' : '#0e57b5')};
`;

export const InputComponent = styled.input<ColorProps>`
  font-family: Mulish;
  padding-top: 1.8rem;
  text-align: left;
  font-size: 1.2rem;
  padding-left: 1rem;
  height: 80px;
  border: 0.5px solid #9aa0ba;
  letter-spacing: 0.8px;
  border-radius: 5px;
  color: #050a24;
  z-index: 1;
  background-color: #f9fbfd;
  outline: none;
`;

export const Svg = styled.svg<VisibleProps>`
  fill: #6fcaff;
  position: absolute;
  left: 88%;
  width: 30px;
  height: 25px;
  top: 30px;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;
