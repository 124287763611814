import styled from '@emotion/styled';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Main = styled.div`
  width: 100%;
  min-height: calc(100vh - 330px);
  margin: 0;
  padding: 0;
`;
