import styled from '@emotion/styled';

interface inputProps {
  placeholder: string;
}

interface labelProps {
  children: string;
}

export const Container = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  svg {
    fill: #6fcaff;
    position: absolute;
    left: 93%;
    margin: 20px 0;
    width: 30px;
    height: 25px;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Label = styled.label<labelProps>`
  position: absolute;
  margin-top: 0.7rem;
  padding-left: 0.8rem;
  text-align: left;
  font-family: 'Mulish', sans-serif;
  text-transform: uppercase;
  font-family: Mulish;
  font-style: normal;
  font-size: 1rem;
  color: #9aa0ba;
`;

export const InputComponent = styled.input<inputProps>`
  font-family: 'Mulish', sans-serif;
  height: 60px;
  padding-top: 1.1rem;
  padding-bottom: 1.2rem;
  padding-left: 0.8rem;
  font-size: 1.2rem;
  width: calc(100%);
  border: 0.3px solid;
  border-color: '#9298b5';
  letter-spacing: 0.8px;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  color: #050a24;
  z-index: 1;
  background: #f9fbfd;
  border: 0.5px solid #9aa0ba;
  box-sizing: border-box;
  border-radius: 5px;

  ::placeholder,
  :-ms-input-placeholder,
  ::-moz-placeholder,
  :-moz-placeholder,
  ::-webkit-input-placeholder {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #9aa0ba;
  }
`;
