import styled from 'styled-components';

export const Container = styled.div`
  width: 212px;
  height: 89px;
  background-color: white;
  border-radius: 5px;
  border: 0px.5 solid #f2f2f2;
  box-shadow: 0px 04px 12px rgba(5, 10, 36, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const P = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #444655;
  span {
    font-size: 1.2rem;
    color: #a8aabc;
  }
`;

export const Div = styled.div`
  display: flex;
  margin-top: 10px;
  span {
    font-size: 1rem;
    font-weight: 600;
    color: #a8aabc;
  }
`;
export const Svg = styled.svg`
  width: 25px;
  height: 25px;
  fill: #a8aabc;
`;
