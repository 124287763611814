import styled from '@emotion/styled';

interface TextAreaProps {
  isGreyScale?: boolean;
}

export const Container = styled.div`
  width: 100%;
  p {
    margin: 20px 0;
  }
  form {
    display: flex;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    flex: 1;
  }
`;

export const FormLeft = styled.div`
  width: 100%;
  padding-right: 20px;
`;

export const FormRight = styled.div<TextAreaProps>`
  width: 100%;
  padding-left: 20px;
  min-height: 333px;
  div {
    background-color: transparent;
    padding: 5px;

    border: 0.5px solid;
    border-color: ${({ isGreyScale }) => (isGreyScale ? '#9aa0ba' : '#6fcaff')};
    border-radius: 5px;

    label {
      color: ${({ isGreyScale }) => (isGreyScale ? '#9aa0ba' : '#6fcaff')};
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  min-height: 333px;
  background-color: transparent;
  padding-top: 5px;
  font-size: 16px;
  border: none;
  resize: none;

  ::placeholder {
    color: #9aa0ba;
  }
`;
