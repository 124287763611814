import Input from '../../components/Input';
import Button from '../../components/Button/index';
import { Container, FormLeft, FormRight, TextArea } from './styles';
import { SCHEDULE_ICON } from '../../utils/constants/svgs';
import { showMessage } from '../../hooks/messages';
import api from '../../services/api';
import {
  formatExpirationDate,
  isValidDate,
} from '../../utils/functions/Date/index';

import React from 'react';
import { SelectComponent } from '../../components/Select';
import { renderError } from '../../hooks/error';

function NewThemeContainer() {
  const selectOptions = ['Aberto para submissão', 'Fechado para submissão'];
  const emptyState = {
    name: '',
    limit_date: '',
    proposal: '',
    video: '',
    topic: '',
    availability: true,
  };

  const [form, setForm] = React.useState(emptyState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowStatus, setIsShowStatus] = React.useState(false);

  const handleSelectStatus = (str: string) => {
    setForm((previousForm) => ({
      ...previousForm,
      availability: str === 'Aberto',
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { name, limit_date, topic, availability, proposal, video } = form;
    if (!name.trim() || !topic.trim() || !proposal.trim() || !video.trim()) {
      showMessage({
        intent: 'error',
        message: 'Formulário com campos vazios. Verifique a informações',
      });
      return;
    }

    if (!isValidDate(limit_date)) {
      showMessage({ intent: 'error', message: 'Data inválida' });
      return;
    }
    setIsLoading(true);

    try {
      await api.post('/themes', {
        name,
        limit_date: formatExpirationDate(limit_date),
        topic,
        availability,
        proposal,
        video,
      });
      showMessage({
        intent: 'success',
        message: 'Tema cadastrado com sucesso!',
      });
      setForm(emptyState);
    } catch (err) {
      renderError(err);
      showMessage({
        intent: 'error',
        message: 'Erro ao cadastrar novo tema. Verifique as informações',
      });
    }
    setIsLoading(false);
  };

  const handleChange =
    (field: string | number | symbol) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setForm((previousForm) => ({ ...previousForm, [field]: value }));
    };

  return (
    <Container>
      <h1>Novo tema</h1>
      <p>Insira os dados para cadastrar um novo tema de redação no portal</p>
      <form onSubmit={handleSubmit}>
        <FormLeft>
          <Input
            type="text"
            name="name"
            labelText="Título"
            placeholder="Digite o tema"
            change={handleChange}
            value={form.name}
            isGreyScale={!form.name}
            required
          />
          <SelectComponent
            label="Status"
            value={
              form.availability
                ? 'Aberto para submissão'
                : 'Fechado para submissão'
            }
            isGreyScale={false}
            options={selectOptions}
            change={handleSelectStatus}
            show={isShowStatus}
            setShow={() => setIsShowStatus(!isShowStatus)}
          />
          <Input
            type="text"
            name="limit_date"
            labelText="Data de Encerramento"
            placeholder="Dia/Mês/Ano"
            change={handleChange}
            value={form.limit_date}
            isGreyScale={!form.limit_date}
            icon={SCHEDULE_ICON}
            required
          />
          <Input
            type="text"
            name="proposal"
            labelText="Proposta"
            placeholder="Digite o link da proposta"
            change={handleChange}
            value={form.proposal}
            isGreyScale={!form.proposal}
            required
          />
          <Input
            type="text"
            name="video"
            labelText="Vídeo"
            placeholder="Digite link do vídeo"
            change={handleChange}
            isGreyScale={!form.video}
            value={form.video}
            required
          />
        </FormLeft>
        <FormRight isGreyScale={!form.topic}>
          <div>
            <label htmlFor="topic">Informações Adicionais</label>
            <TextArea
              name="topic"
              placeholder="Insira dicas e lembretes a respeito deste tema de redação"
              onChange={(e) => setForm({ ...form, topic: e.target.value })}
              value={form.topic}
              required
            />
          </div>
          <Button type="submit" disabled={isLoading} text="Cadastrar" />
        </FormRight>
      </form>
    </Container>
  );
}

export default NewThemeContainer;
