import { Container } from './styles';
import { CHECKBOX } from '../../utils/constants/svgs';

type CheckBoxItemButtonProps = {
  isSelected: boolean;
  colorSvg?: string;
  borderColor?: string;
  backgroundColor?: string;
};

function CheckBoxItemButton({ isSelected }: CheckBoxItemButtonProps) {
  return (
    <Container isSelected={isSelected}>
      {isSelected && (
        <svg>
          <path d={CHECKBOX} />
        </svg>
      )}
    </Container>
  );
}

export default CheckBoxItemButton;
