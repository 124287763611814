import styled from '@emotion/styled';

export const Content = styled.div`
  width: 100%;
  min-height: 60vh;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 3% auto;
`;

export const Option = styled.div`
  width: 25%;
`;
