import { ComponentType } from 'react';
import { RouteProps as ReactRouteProps } from 'react-router-dom';
import Login from '../pages/Login';
import Correctors from '../pages/Correctors';
import Corrector from '../pages/Corrector';
import Students from '../pages/Student';
import RecoverPassword from '../pages/RecoverPassword';
import ForgotPassword from '../pages/ForgotPassword';
import NewTeacher from '../pages/NewTeacher';
import NotFound from './../pages/404/index';
import EssayPage from './../pages/Essay/index';
import NewTheme from '../pages/NewTheme';
import MoveEssayByTeacher from '../pages/MoveEssayByTeacher';
import DashboardLectures from '../pages/DashboardLectures';
import Lecture from '../pages/Lecture';

export interface RouteProps extends ReactRouteProps {
  component: ComponentType;
  private?: boolean;
  routes?: RouteProps[];
}

const options: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: Login,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/correctors',
    exact: true,
    private: true,
    component: Correctors,
  },
  {
    path: '/new-teacher',
    exact: true,
    private: true,
    component: NewTeacher,
  },
  {
    path: '/corrector/:id',
    exact: true,
    private: true,
    component: Corrector,
  },
  {
    path: '/students',
    exact: true,
    private: true,
    component: Students,
  },
  {
    path: '/lecture/:id',
    exact: true,
    private: true,
    component: Lecture,
  },
  {
    path: '/recover-password',
    exact: true,
    component: RecoverPassword,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
  },
  {
    path: '/essays',
    exact: true,
    private: true,
    component: EssayPage,
  },
  {
    path: '/new-theme',
    exact: true,
    private: true,
    component: NewTheme,
  },
  {
    path: '/essays-theme/:idTheme',
    exact: true,
    private: true,
    component: MoveEssayByTeacher,
  },
  {
    path: '/lectures-dashboard/:id',
    exact: true,
    private: true,
    component: DashboardLectures,
  },
  {
    path: '/*',
    exact: true,
    component: NotFound,
  },
];

export default options;
