import api from '../services/api';
import { getUser, saveUser } from './auth';
import { showMessage } from './messages';
import { renderError } from './error';
import { USER_ROLE_TEACHER } from '../config/user/user';
import { URL_TEACHER_FIRST_ACCESS } from './../config/urls/portal';
import { newTeacherEmail } from './email';
import { MAX_STUDENTS_GROUP_TEACHER } from '../config/essay-group';

interface UserProps {
  id?: string;
  name: string;
  email: string;
  password?: string;
  specialty: string;
}

interface EssayGroupsTeacherProps {
  id_teacher: number;
  name: string;
}

interface UserEditSimpleProps {
  id: string;
  name: string;
  email: string;
  phone: string;
}

interface UpdateTeacherAddingGroupId {
  id_teacher: number;
  id_group: number;
}

interface MessageProps {
  status: 'success' | 'error' | 'warning';
  message: string;
}

type TeacherPasswordProps = {
  id: string;
  password: string;
};

export const changeCustomFields = async ({
  id,
  name,
  email,
  phone,
}: UserEditSimpleProps) => {
  try {
    await api.put(`users/${id}`, {
      name,
      email,
      phone,
    });
    let user = getUser();
    if (user) {
      let updateUser = JSON.parse(user);
      updateUser.name = name;
      updateUser.email = email;
      updateUser.phone = phone;
      saveUser(updateUser);
    }
  } catch (err) {
    renderError(err);
    showMessage({ intent: 'error', message: 'Erro ao tentar alterar usário' });
  }
};

export const resetPassword = async (password: string) => {
  let user = getUser();
  if (user) {
    let id = JSON.parse(user).id;
    try {
      await api.put(`users/${id}`, {
        password,
      });
      showMessage({
        intent: 'success',
        message: 'Senha atualizada com sucesso',
      });
    } catch (err) {
      showMessage({ intent: 'error', message: 'Erro ao atualizar a senha' });
    }
  }
};

export const createTeacher = async ({ name, email, specialty }: UserProps) => {
  const id = getUser().id;
  let newPassword = Math.floor(Math.random() * new Date().getTime());
  try {
    const newUser = await api.post('/users', {
      username: `${name} ${new Date().getTime()}`,
      name,
      email,
      specialty,
      password: newPassword,
      redefinedPassword: false,
      role: USER_ROLE_TEACHER,
      confirmed: true,
    });
    showMessage({
      intent: 'success',
      message: 'Professor criado com sucesso!!',
    });
    await api.post('/notifies', {
      text: 'Bem-vindo ao time aluno ensina',
      idSender: id,
      idReceiver: newUser.data.id,
    });
    showMessage({
      intent: 'success',
      message: 'Notificação enviada com sucesso!',
    });
    await newTeacherEmail({
      teacherName: name,
      code: newPassword.toString(),
      email: email,
      link: URL_TEACHER_FIRST_ACCESS,
    });
    return newUser.data.id;
  } catch (err) {
    renderError(err);
    return false;
  }
};

export const createEssayGroup = async ({
  name,
  id_teacher,
}: EssayGroupsTeacherProps) => {
  try {
    const response = await api.post('/essay-groups', {
      name,
      id_teacher,
      student_limit: MAX_STUDENTS_GROUP_TEACHER,
      actived: true,
    });
    return response.data.id;
  } catch (err) {
    showMessage({
      intent: 'error',
      message: 'Erro ao criar o grupo de redação',
    });
    renderError(err);
    return false;
  }
};

export const updateTeacherPassword = async (
  teacher: TeacherPasswordProps
): Promise<MessageProps | undefined> => {
  try {
    const response = await api.put(`/users/${teacher.id}`, {
      password: teacher.password,
    });
    if (response.status === 200) {
      return { status: 'success', message: 'Professor alterado com sucesso!' };
    }
  } catch (err) {
    return {
      status: 'error',
      message: 'Não foi possivel alterar o professor.',
    };
  }
};

export const updateTeacher = async (user: UserProps) => {
  try {
    const id = getUser().id;
    if (!user.password?.length) {
      delete user.password;
    }
    const newUser = await api.put(`/users/${user.id}`, user);
    showMessage({
      intent: 'success',
      message: 'Professor alterado com sucesso!',
    });

    const newNotify = await api.post('/notifies', {
      text: 'Professor alterado com sucesso!',
      idSender: id,
      idReceiver: newUser.data.id,
    });
    return newNotify.data;
  } catch (err) {
    renderError(err);
  }
};

export async function updateTeacherAddingGroupId({
  id_teacher,
  id_group,
}: UpdateTeacherAddingGroupId) {
  try {
    const response = await api.put(`/users/${id_teacher}`, {
      group: id_group,
    });
    return response.data;
  } catch (err) {
    renderError(err);
    showMessage({ intent: 'error', message: 'Erro inesperado' });
  }
}

export const newUserPassword = async (password: string) => {
  try {
    const updatedUser = await api.put('/auth/new_password', {
      password,
    });
    return updatedUser.data;
  } catch (err) {
    renderError(err);
  }
};
