import * as React from 'react';
import { Item } from './styles';

interface OptionItemProps {
  children: React.ReactNode;
  selected?: boolean;
}

const OptionItem = ({ children, selected = false }: OptionItemProps) => (
  <Item selected={selected}>{children}</Item>
);

export default OptionItem;
