import api from '../../services/api';
import Back from '../../components/Back';
import CheckBoxItemButton from '../../components/CheckBoxItemButton';
import TablePaginationHeader from '../../components/TablePaginationHeader';
import TableItemEssayTheme from '../../components/ItemTableEssayTheme';
import { ThemeProps } from '../../domain/theme';
import { EssayProps } from '../../domain/essay';
import { formatDate } from './../../utils/functions/Date';
import { useParams, useHistory } from 'react-router-dom';
import React from 'react';
import Search from '../../components/Search';
import { UserProps } from '../../domain/user';
import { USER_ROLE_TEACHER } from '../../config/user/user';
import ItemModalTeacherInfo from '../../components/ItemModalTeacherInfo';
import { showMessage } from './../../hooks/messages';
import { renderError } from '../../hooks/error';
import { EssayStatusProps } from '../../domain/essayStatus';
import {
  Container,
  HeaderBack,
  BreadCrumbs,
  Title,
  TagGroups,
  TagRight,
  TagLeft,
  GroupHeaderButtons,
  GroupButtons,
  ButtonModal,
  ButtonInfoModal,
  BoxModal,
  GroupItems,
  FooterModal,
  EssaySelectedModal,
  EssaysSelectedBodyModal,
} from './styles';

interface ParamsProps {
  idTheme: string;
}
interface TeacherProps {
  name: string;
  id: number;
  essayNumber: number;
}

function MoveEssayByTeacher() {
  const history = useHistory();
  const PAGINATION_LIMIT = 8;
  const { idTheme } = useParams<ParamsProps>();
  const [isMarkAllSelected, setIsMarkAllSelected] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [searchTeacher, setSearchTeacher] = React.useState('');
  const [totalEssays, setTotalStudents] = React.useState(0);
  const [selectTypeTag, setSelectTypeTag] = React.useState(false);
  const [pages, setPages] = React.useState<number[]>([]);
  const [teachers, setTeachers] = React.useState<TeacherProps[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [essays, setEssay] = React.useState<EssayProps[]>([]);
  const [isTeacherSelected, setTeacherSelect] = React.useState(0);
  const [isHoverSelectedEssays, setIsHoverSelectedEssays] =
    React.useState(false);
  const [isSelectedEssays, setSelectedEssays] = React.useState<EssayProps[]>(
    []
  );
  const [currentTheme, setCurrentTheme] = React.useState<ThemeProps>({
    name: '',
  } as ThemeProps);
  const [startOffset, setStartOffset] = React.useState(1);

  function updateTeacherBasedEssayMove() {
    let updatedTeacher: TeacherProps[] = [];
    for (let teacher of teachers) {
      teacher.essayNumber = essays.filter(
        (essay) => essay.id_teacher.id === teacher.id
      ).length;
      updatedTeacher.push(teacher);
    }
    setTeachers(updatedTeacher);
  }

  React.useEffect(() => {
    api
      .get<UserProps[]>(`/users?role=${USER_ROLE_TEACHER}`)
      .then(({ data }) => {
        let newArrayTeacher: TeacherProps[] = [];
        for (let user of data) {
          newArrayTeacher.push({
            id: user.id,
            name: user.name,
            essayNumber: 0,
          });
        }
        setTeachers(newArrayTeacher);
      })
      .then(() => {
        api.get<EssayProps[]>(`/essays?theme=${idTheme}`).then(({ data }) => {
          setEssay(data);
          setTotalStudents(data.length);
          const countTotalPagesBasedUserNumberAndLimit = Math.ceil(
            data.length / PAGINATION_LIMIT
          );

          const newPages = Array.from(
            Array(countTotalPagesBasedUserNumberAndLimit)
          ).map((_, i) => i + 1);
          setPages(newPages);
        });
      })
      .catch((err) => {
        renderError(err);
        showMessage({ intent: 'error', message: 'Erro ao carregar redações' });
        history.push('/essays');
      });
  }, [history, idTheme]);

  React.useEffect(() => {
    api
      .get<ThemeProps[]>(`/themes?id=${idTheme}`)
      .then(({ data }) => {
        setCurrentTheme(data[0]);
      })
      .catch((err) => {
        renderError(err);
        showMessage({
          intent: 'error',
          message: 'O tema de redação não existe',
        });
        history.push('/essays');
      });
  }, [history, idTheme]);

  React.useEffect(() => {
    setStartOffset((currentPage - 1) * PAGINATION_LIMIT);
  }, [currentPage]);

  const handleExistsEssayselected = (id: number): number =>
    isSelectedEssays.findIndex((essay) => essay.id === id);

  const handleSelectEssay = (id: number): void => {
    if (handleExistsEssayselected(id) === -1) {
      const indexNewEssay = essays.findIndex((essay) => essay.id === id);
      isSelectedEssays.push(essays[indexNewEssay]);
    } else {
      const indexOf = isSelectedEssays.findIndex((essay) => essay.id === id);
      isSelectedEssays.splice(indexOf, 1);
      setIsOpenModal(!!isSelectedEssays.length);
    }
    setSelectedEssays([...isSelectedEssays]);
  };

  const handleSelectTeacher = (id: number): void => {
    setTeacherSelect(id);
  };

  const handleRenderTeachers = () => {
    return searchTeacher
      ? teachers
          .filter((teacher) => teacher.name.includes(searchTeacher))
          .map((teacher) => (
            <ItemModalTeacherInfo
              key={`${teacher.name}${teacher.name}`}
              id_teacher={teacher}
              totalEssay={teacher.essayNumber}
              teacher_name={teacher.name}
              isSelected={teacher.id === isTeacherSelected}
              setSelectTeacher={handleSelectTeacher}
            />
          ))
      : teachers.map((teacher) => (
          <ItemModalTeacherInfo
            key={`${teacher.name}${teacher.id}`}
            id_teacher={teacher}
            totalEssay={teacher.essayNumber}
            teacher_name={teacher.name}
            isSelected={teacher.id === isTeacherSelected}
            setSelectTeacher={handleSelectTeacher}
          />
        ));
  };

  const handleErrorSelectEssay = () =>
    showMessage({
      intent: 'error',
      message: 'Não é possível selecionar uma redação corrigida',
    });

  const handleChangeCurrentPage = (current: number): void =>
    setCurrentPage(current);

  const handleMarkAllEssaySelected = () => {
    const selectedEssays: EssayProps[] = essays
      .filter(
        (essay) =>
          (essay.essay_status as EssayStatusProps).status !== 'Confirmada'
      )
      .slice(startOffset, startOffset + PAGINATION_LIMIT)
      .map((essay) => essay);
    setSelectedEssays(selectedEssays);
    setIsMarkAllSelected(!isMarkAllSelected);
  };

  const handleClear = () => {
    setIsOpenModal(false);
    setIsMarkAllSelected(false);
    setSelectedEssays([]);
    setTeacherSelect(0);
  };

  const handleSearchTeacher = (search: string): void =>
    setSearchTeacher(search);

  const handleMoveEssays = async (): Promise<void> => {
    if (!isSelectedEssays.length)
      return showMessage({
        intent: 'error',
        message: 'É necessário selecionar uma redação',
      });

    for (let essay of isSelectedEssays) {
      try {
        const idOldTeacher = teachers.findIndex(
          (teacher) => teacher.id === essay.id_teacher.id
        );
        teachers[idOldTeacher].essayNumber =
          teachers[idOldTeacher].essayNumber - 1;

        await api.put(`/essays/${essay.id}`, { id_teacher: isTeacherSelected });
        const oldEssay = essays.findIndex(
          (oldEssay) => oldEssay.id === essay.id
        );
        essays[oldEssay].id_teacher.id = isTeacherSelected;

        const idNewTeacher = teachers.findIndex(
          (teacher) => teacher.id === isTeacherSelected
        );

        teachers[idNewTeacher].essayNumber =
          teachers[idNewTeacher].essayNumber + 1;
        showMessage({
          intent: 'success',
          message: 'Redação alterada com sucesso!',
        });
      } catch (err) {
        renderError(err);
        showMessage({ intent: 'error', message: 'Erro ao alterar o usuário' });
      }
    }
    setEssay([...essays]);
    setTeachers([...teachers]);

    handleClear();
  };

  function openModal() {
    updateTeacherBasedEssayMove();
    setIsOpenModal(!isOpenModal);
  }

  return (
    <Container>
      <HeaderBack>
        <Back />
        <BreadCrumbs>
          Temas <span> / {currentTheme?.name} </span>
        </BreadCrumbs>
      </HeaderBack>

      <Title>
        <h1>{currentTheme?.name}</h1>
        <h3>Tema de redação</h3>
      </Title>
      <TagGroups>
        <TagLeft
          type="button"
          isSelected={selectTypeTag === false}
          onClick={() => setSelectTypeTag(false)}
        >
          Descrição
        </TagLeft>
        <TagRight
          type="button"
          isSelected={selectTypeTag === true}
          onClick={() => setSelectTypeTag(true)}
        >
          Submissões
        </TagRight>
      </TagGroups>

      <GroupHeaderButtons>
        <GroupButtons>
          <ButtonModal
            type="button"
            backgroundColor="#0E57B5"
            color="white"
            onClick={() => {
              isMarkAllSelected ? handleClear() : handleMarkAllEssaySelected();
            }}
          >
            <CheckBoxItemButton
              colorSvg="#0E57B5"
              isSelected={isMarkAllSelected}
            />
            {isMarkAllSelected ? 'Desmarcar Todos' : 'Marcar Todos'}
          </ButtonModal>
          <ButtonInfoModal
            color="#0E57B5"
            backgroundColor="white"
            onClick={() => {
              isSelectedEssays.length
                ? openModal()
                : showMessage({
                    intent: 'error',
                    message:
                      'É necessário selecionar alguma redação antes de mover!',
                  });
            }}
          >
            Mover Redações
          </ButtonInfoModal>
          <BoxModal isOpenModel={isOpenModal}>
            <h3>Mover Redações</h3>
            <Search
              placeholder="Digite o nome de um professor"
              name="searchTeacher"
              change={handleSearchTeacher}
              value={searchTeacher}
              labelText=""
              type="text"
            />
            <GroupItems>{handleRenderTeachers()}</GroupItems>
            <FooterModal>
              <ButtonModal
                backgroundColor="#0E57B5"
                color="white"
                onClick={() => handleMoveEssays()}
              >
                Mover Alunos
              </ButtonModal>
            </FooterModal>
          </BoxModal>
        </GroupButtons>
        <EssaySelectedModal
          onMouseEnter={() => {
            setIsHoverSelectedEssays(true);
          }}
          onMouseLeave={() => {
            setIsHoverSelectedEssays(false);
          }}
        >
          {isSelectedEssays.length} redações selecionadas
          <EssaysSelectedBodyModal isHover={isHoverSelectedEssays}>
            {isSelectedEssays.map((essay) => (
              <p key={(essay.idUser as UserProps).name}>{(essay.idUser as UserProps).name}</p>
            ))}
          </EssaysSelectedBodyModal>
        </EssaySelectedModal>
      </GroupHeaderButtons>
      <table>
        <tbody>
          {essays.map((essay) => (
            <TableItemEssayTheme
              key={essay.created_at}
              id={essay.id}
              status={(essay.essay_status as EssayStatusProps).status}
              title={essay.title}
              isSelected={
                (essay.essay_status as EssayStatusProps).status !==
                  'Corrigida' && handleExistsEssayselected(essay.id) !== -1
                  ? true
                  : false
              }
              created_by={(essay.idUser as UserProps).name}
              created_at={formatDate(essay.created_at)}
              click={
                (essay.essay_status as EssayStatusProps).status !== 'Corrigida'
                  ? handleSelectEssay
                  : handleErrorSelectEssay
              }
            />
          ))}
        </tbody>
      </table>
      <TablePaginationHeader
        currentPage={currentPage}
        handleClick={handleChangeCurrentPage}
        pages={pages}
        limit={PAGINATION_LIMIT}
        totalItems={totalEssays}
        label="redações"
      />
    </Container>
  );
}

export default MoveEssayByTeacher;
