import styled from '@emotion/styled';

type IBoolean = {
  prop?: boolean;
};

const InitialButton = styled.button`
  display: flex;
  color: #444655;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 1rem;
  width: max-content;
  background-color: #fff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #f2f2f2cc;
  }
`;

export const Subtitle = styled.h4`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Button = styled(InitialButton)<IBoolean>`
  margin-right: 0.5rem;
  border: ${({ prop }) => (prop ? '2px solid #0e57b5' : '2px solid #f2f2f2')};
  svg {
    fill: ${({ prop }) => (prop ? '#0e57b5' : '#a8aabc')};
  }
`;

export const Area = styled.div<IBoolean>`
  display: ${({ prop }) => (prop ? 'flex' : 'none')};
  margin-bottom: 1rem;
`;

export const Rating = styled(InitialButton)<IBoolean>`
  border: 2px solid #f2f2f2;
  svg {
    width: 13px;
    height: 8px;
    fill: #444655;
    margin: 0px 0.5rem 0px 0.5rem;
    transform: ${({ prop }) => (prop ? 'unset' : 'rotateZ(180deg)')};
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 1rem;
`;

export const SearchInput = styled.div`
  display: flex;
  width: 50%;
  min-width: 350px;
  border: 2px solid #f2f2f2;
  border-radius: 5px;
  background-color: #fff;
  align-items: center;
  input {
    width: 100%;
    height: 32px;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    ::placeholder {
      color: #a8aabc;
      opacity: 1;
    }
  }
  svg {
    width: 25px;
    height: 25px;
    margin: 0px 0.6rem 0px 0.9rem;
    fill: #a8aabc;
  }
`;

export const TableHeaderFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  svg {
    transform: none;
    margin: 0px 0.5rem 0px 0px;
    width: 20px;
    height: 20px;
    fill: #444655;
  }
`;

export const Circle = styled.div`
  background-color: #0e57b5;
  color: #fff;
  border-radius: 100px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  margin-right: 0.5rem;
`;
