import styled from '@emotion/styled';

type ContainerProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const Container = styled.button<ContainerProps>`
  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${({ isSelected }) => (isSelected ? '#6FCAFF' : 'white')};
  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    color: ${({ isSelected, isDisabled }) =>
      isSelected ? 'white' : isDisabled ? '#AEB3C8' : '#9AA0BA'};

    font-weight: bold;
  }
`;
export const Content = styled.div<ContainerProps>`
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ isSelected, isDisabled }) =>
    isSelected ? 'white' : isDisabled ? '#CDCED3' : '#9AA0BA'};
`;
