import styled from '@emotion/styled';
import Svg from '../../components/Svg';
import { keyframes } from '@emotion/react';

type BooleanProps = {
  center?: boolean;
  prop?: boolean;
};

type GroupProps = {
  isDisabled: boolean;
  isSelected: boolean;
};

const GrowthAnimation = keyframes`
from {
  max-height: 0px;
  padding: 0px 1rem;
  overflow: hidden;
}
to {
  max-height: 400px;
}
`;

export const Container = styled.div`
  font-family: Mulish;
  width: 100%;
  min-height: 67vh;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
`;

export const Loading = styled.div`
  padding: 30vh 0px;
`;

export const Circle = styled.div`
  background-color: #0e57b5;
  color: #fff;
  border-radius: 100px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  margin-right: 0.5rem;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  div:first-of-type {
    margin-right: 2rem;
  }
`;

export const TableHead = styled.thead`
  tr {
    display: grid;
    grid-template-columns: 30px 1.4fr 0.8fr 1.4fr 0.7fr 0.7fr 1fr 30px;
    grid-gap: 0.2rem;
    align-items: center;
    background-color: #fafbfb;
    border-radius: 5px;
    padding: 0.5rem 10px;
    background-color: #f4f4f5;
    th {
      height: 40px;
    }
  }
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const TableBody = styled.tbody`
  tr:nth-of-type(odd) {
    background-color: #fafbfb;
  }
`;

export const TD = styled.td<BooleanProps>`
  max-width: 250px;
  height: 40px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #050a24;
  overflow: hidden;
  justify-content: ${({ center }) => (center ? 'center' : 'initial')};
`;

export const SvgApproved = styled(Svg)`
  margin: 0 5px;
  width: 25px;
  height: 22px;
  fill: #9aa0ba;
`;

export const TR = styled.tr`
  display: grid;
  cursor: pointer;
  grid-template-columns: 30px 1.4fr 0.8fr 1.4fr 0.7fr 0.7fr 1fr 30px;
  grid-gap: 0.2rem;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem 10px;
`;

export const TH = styled.th<BooleanProps>`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  max-width: 250px;
  cursor: pointer;
`;

export const SvgArrow = styled(Svg)<BooleanProps>`
  margin-left: 5px;
  width: 13px;
  height: 10px;
  transform: ${({ prop }) => (prop ? 'unset' : 'rotateZ(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

export const SvgSearch = styled(Svg)`
  width: 24px;
  height: 24px;
`;

export const Modal = styled.div`
  top: 430px;
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-top: 1rem;
  margin-right: 150px;
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  position: absolute;
  animation: ${GrowthAnimation} 250ms ease-in-out;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  z-index: 11;
`;

export const Content = styled.div`
  max-height: 310px;
  overflow: scroll;
`;

const Disabled = `
  h2 {
    color: #aeb3c8;
  }
  p {
    color: #cdced3;
  }
  span {
    color: #aeb3c8;
  }
`;

const Selected = `
  background: #6fcaff;
  h2 {
    color: #fff;
  }
  p {
    color: #fff;
  }
  span {
    color: #fff;
  }
`;

export const Group = styled.div<GroupProps>`
  border-radius: 5px;
  margin-top: 0.8rem;
  border: 1px solid #ebecf1;
  cursor: ${({ isDisabled }) => (isDisabled ? 'unset' : 'pointer')};
  padding: 0.6rem;
  ${({ isDisabled }) => isDisabled && Disabled};
  ${({ isSelected }) => isSelected && Selected};
`;

export const Teacher = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GroupName = styled.h2`
  font-size: 1rem;
  color: #050a24;
  font-weight: bold;
`;

export const TeacherName = styled.p`
  color: #373b50;
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;

export const Span = styled.span`
  color: #6fcaff;
  font-size: 0.8rem;
`;

export const Confirm = styled.button`
  border-radius: 5px;
  background: #0e57b5;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  width: max-content;
  align-self: end;
  cursor: pointer;
`;
