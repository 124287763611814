import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface ItemProps {
  selected?: boolean;
}

interface ItemsProps {
  show?: boolean;
}

const GrowthAnimation = keyframes`
from {
  max-height: 0px;
  padding: 0px 1rem;
  overflow: hidden;
}

to {
  max-height: 400px;
}
`;

export const Container = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h2 {
    font-weight: bold;
    font-size: 1rem;
    color: #444655;
    margin-bottom: 0.3rem;
  }
`;

export const Items = styled.div<ItemsProps>`
  overflow: hidden;
  margin: 0px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  min-width: 250px;
  margin-top: 4rem;
  max-height: 400px;
  overflow-y: scroll;
  background-color: #fefeff;
  border-radius: 16px;
  padding: 1rem;
  position: absolute;
  animation: ${GrowthAnimation} 400ms ease-in-out;
  z-index: 11;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  button {
    color: #050a24;
    text-transform: uppercase;
    font-size: 1rem;
    border: none;
    background: none;
    text-align: left;
    margin: 0px;
    padding: 1rem;
    border-radius: 8px;
  }
  button:not(:first-of-type) {
    margin-top: 0.5rem;
  }
  button:hover {
    background-color: #3e79c4;
    color: #fff;
  }
  button:hover:last-of-type {
    background-color: #a8aabc;
    color: #fff;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #a8aabc;
  border-radius: 5px;
  background: #fff;
  padding: 0.5rem 1rem;
  min-width: 250px;
  color: #444655;
  label {
    cursor: pointer;
    letter-spacing: 0px;
  }
  input {
    width: 100%;
    height: 32px;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    ::placeholder {
      color: #a8aabc;
      opacity: 1;
    }
  }
`;

export const Svg = styled.svg<ItemsProps>`
  cursor: pointer;
  height: 10px;
  width: 12px;
  fill: #9aa0ba;
  margin-left: 0.5rem;
  transform: ${({ show }) => (show ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
`;
