import { FormEvent, useState } from 'react';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Back from '../../components/Back';

import { Container, Content, ContentButton } from './styles';
import { showMessage } from '../../hooks/messages';
import { resetUserPassword } from '../../hooks/auth';
import { renderError } from '../../hooks/error';
import { useParams } from 'react-router-dom';

interface ResetProps {
  code: string;
}
const RecoverPassword = () => {
  const { code } = useParams<ResetProps>();

  const [form, setForm] = useState({
    password: '',
    confirm_password: '',
  });

  const handleChange =
    (field: string | number | symbol) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setForm((previousForm) => ({ ...previousForm, [field]: value }));
    };

  const submit = (e: FormEvent) => {
    e.preventDefault();

    if (form.password !== form.confirm_password) {
      showMessage({ intent: 'error', message: 'As senhas não se conferem!' });
      return;
    }

    if (!form.password.length) {
      showMessage({
        intent: 'error',
        message: 'O campo de senha não pode ficar vazio.',
      });
      return;
    }

    resetUserPassword(code, form.password, form.confirm_password)
      .then(() => {
        showMessage({
          intent: 'success',
          message: 'Senha alterada com sucesso!',
        });
        setForm({ password: '', confirm_password: '' });

        return;
      })
      .catch((err) => {
        renderError(err);
        showMessage({
          intent: 'error',
          message: 'Erro ao atualizar senha. Por favor, tente novamente',
        });
      });
  };

  return (
    <Container>
      <ContentButton>
        <a href="/">
          <Back />
        </a>
      </ContentButton>
      <Content>
        <h2>Defina uma nova senha de acesso</h2>
        <p>Ao definir uma nova senha você precisará fazer login novamente.</p>
        <form onSubmit={submit}>
          <Input
            placeholder="Defina sua nova senha"
            labelText="nova senha"
            name="password"
            type="password"
            change={handleChange}
            passwordVisible
          />
          <Input
            placeholder="Confirme sua nova senha"
            labelText="confirme sua nova senha"
            name="confirm_password"
            type="password"
            change={handleChange}
            passwordVisible
          />
          <Button type="submit" text="definir nova senha" />
        </form>
      </Content>
    </Container>
  );
};
export default RecoverPassword;
