import styled from '@emotion/styled';
import LoadingComponent from '../../components/Loading';
import Svg from '../../components/Svg';

type IBoolean = {
  prop?: boolean;
};

export const Loading = styled(LoadingComponent)`
  padding: 30vh 0px;
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  div:first-of-type {
    margin-right: 2rem;
  }
`;

export const Container = styled.div`
  font-family: Mulish;
  width: 100%;
  min-height: 67vh;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const TR = styled.tr`
  display: grid;
  cursor: pointer;
  grid-template-columns: 80px 130px 1fr 1fr 1fr 40px;
  grid-gap: 0.2rem;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  padding: 0.5rem 10px;
  td:first-of-type {
    svg {
      margin: 0 5px;
      width: 20px;
      height: 20px;
      fill: #ffd600;
    }
  }
`;

export const TableHead = styled.thead`
  tr {
    display: grid;
    grid-template-columns: 80px 130px 1fr 1fr 1fr 40px;
    grid-gap: 0.2rem;
    align-items: center;
    background-color: #fafbfb;
    border-radius: 5px;
    padding: 0.5rem 10px;
    background-color: #f4f4f5;
    th {
      height: 40px;
    }
  }
`;

export const TH = styled.th`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  max-width: 250px;
  cursor: pointer;
`;

export const SvgArrow = styled(Svg)<IBoolean>`
  margin-left: 5px;
  width: 13px;
  height: 10px;
  transform: ${({ prop }) => (prop ? 'unset' : 'rotateZ(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

export const TableBody = styled.tbody`
  tr:nth-of-type(odd) {
    background-color: #fafbfb;
  }
`;

export const TD = styled.td`
  max-width: 250px;
  height: 20px;
  margin: 10px 0px;
  font-size: 1rem;
  display: flex;
  color: #050a24;
  overflow: hidden;
  div {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 2px;
  }
  span {
    display: flex;
    height: 100%;
    overflow: hidden;
    color: #56b4ea;
    align-items: baseline;
    word-break: break-all;
  }
`;

export const SvgIcon = styled(Svg)`
  margin: 0 5px;
  width: 25px;
  height: 22px;
  fill: #a8aabc;
`;
