import { useState } from 'react';
import * as Select from './styles';
import * as Svg from '../../utils/constants/svgs';

type ItemProp = {
  id: number | null;
  name: string;
};

type SelectProps = {
  text: string;
  options: ItemProp[];
  value: string;
  setValue: (value: ItemProp) => void;
};

const SelectDashboard = ({ text, options, value, setValue }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const Reset = () => (
    <button
      key="reset"
      type="button"
      onClick={() => {
        setIsOpen(false);
        setValue({ id: null, name: 'Selecionar' });
      }}
    >
      Limpar filtro
    </button>
  );

  return (
    <Select.Container>
      <h2>{text}</h2>
      <Select.Item onClick={() => setIsOpen((prevState) => !prevState)}>
        <label>{value}</label>
        <Select.Svg show={isOpen}>
          <path d={Svg.FILTER} />
        </Select.Svg>
      </Select.Item>
      <Select.Items show={isOpen}>
        {options.map(({ id, name }) => (
          <button
            key={id}
            type="button"
            onClick={() => {
              setIsOpen(false);
              setValue({ id, name });
            }}
          >
            {name}
          </button>
        ))}
        <Reset />
      </Select.Items>
    </Select.Container>
  );
};

export default SelectDashboard;
