import React from 'react';
import { Container, P, Div, Svg } from './styles';
interface CardProps {
  text: string;
  label: string;
  svg?: string;
  subInfo?: string;
}

function Card({ text, subInfo, label, svg }: CardProps) {
  return (
    <Container>
      <P>
        {text} {subInfo && <span>{subInfo}</span>}
      </P>
      <Div>
        {svg ? (
          <Svg>
            <path d={svg} />
          </Svg>
        ) : null}
        <span>{label}</span>
      </Div>
    </Container>
  );
}

export default Card;
